<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 300px;">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <legend>Finding type</legend>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="finding_type_name" class="placeholder placeholder-static">Finding type name</label>
                  <input 
                    :class="['form-control', {'disabled': findingTypeArchived}, {'error': v$.findingTypeName.$error}]"
                    placeholder="Name" 
                    type="text" 
                    name="finding_type[name]" 
                    id="finding_type_name"
                    v-model.trim="findingTypeName"
                    @keydown.enter.prevent="submitForm"
                  />
                  <div v-if="v$.findingTypeName.$error" class="error">Finding type name is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-static">Component Type</label>
                  <template v-if="findingTypeFindingCount == 0">
                    <v-select
                      placeholder="Select component type"
                      ref="componentTypes"
                      :class="[{'vs-error': v$.findingTypeComponentTypeId.$error}]"
                      :options="componentTypes"
                      :disabled="findingTypeArchived"
                      label="name"
                      @update:modelValue="setFindingTypeComponentType"
                    />
                  </template>
                  <template v-else>
                    <input 
                      :class="['form-control', {'disabled': findingTypeArchived}]"
                      type="text"
                      readonly
                      :value="findingTypeComponentType.name"
                      @keydown.enter.prevent="submitForm"
                    />
                  </template>
                  <div v-if="v$.findingTypeComponentTypeId.$error" class="error">Component type is required</div>
                  <input
                    type="hidden"
                    id="finding_type_component_type_id"
                    name="finding_type[component_type_id]"
                    :value="findingTypeComponentTypeId"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-static" for="finding_type_description">Finding Notes</label>
                  <textarea 
                    rows="4" 
                    :class="['form-control', {'disabled': findingTypeArchived}]"
                    placeholder="Finding type description" 
                    name="finding_type[description]" 
                    id="finding_type_description"
                    v-model="findingTypeDescription"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row" v-if="isExistingFindingType">
              <div class="col-sm-12">
                <div class="form-group">
                  <div class="form-control-toggle toggle-inline toggle-left toggle-warning">
                    <input 
                      type="checkbox" 
                      id="chkFindingTypeArchive" 
                      :checked="findingTypeArchived"
                      @change="setFindingTypeArchive">
                    <label for="chkFindingTypeArchive">
                      <span>Archive Finding Type</span><span></span>
                    </label>
                  </div>

                  <input
                    name="finding_type[archived]" 
                    type="hidden" 
                    :value="findingTypeArchived"
                  >
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-lg-6">
          <fieldset>
            <legend>Finding type Details</legend>
            <form-data-types
              ref="dataTypes"
              :default-data-types="findingTypeDetails"
              :entity-type-archived="findingTypeArchived"
              entity-type="finding_type"
            />
          </fieldset>
        </div>
      </div>
      <div class="form-group">
        <form-button
          ref="submitButton"
          :label="submitButtonLabel"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import { ref, nextTick } from 'vue'
import vSelect from "vue-select";
import formButton from "../shared/form/form_button";
import formDataTypes from "../shared/data_types/form_data_types";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
    formDataTypes,
    formButton
  },

  props: {
    findingTypeId: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      findingTypeName: "",
      findingTypeDescription: "",
      findingTypeArchived: false,
      findingTypeComponentType: {
        id: "",
        name: ""
      },
      findingTypeComponentTypeId: null,
      findingTypeDetails: null,
      findingTypeFindingCount: 0,
      componentTypes: this.componentTypes,
      isLoaded: false,
      isSpinnerVisible: true,
    }
  },

  validations () {
    return {
      findingTypeName: {
        required
      },
      findingTypeComponentTypeId: {
        required
      }
    }
  },

  computed: {
    submitButtonLabel: function() {
      return (this.isExistingFindingType) ? "Update Finding Type" : "Create Finding Type";
    },

    isExistingFindingType: function() {
      return this.findingTypeId != "";
    }
  },

  mounted: function() {
    if (this.isExistingFindingType) {
      this.fetchFindingType();
    }
    else {
      this.isLoaded = true;

      setTimeout(() => {
        this.isSpinnerVisible = false;
      }, 1000);
    }

    this.fetchComponentTypes();
  },

  methods: {
    setFindingTypeArchive: function($event) {
      if ($event.target.checked) {
        this.isSpinnerVisible = true;

        this.fetchFindingType(() => {
          this.findingTypeArchived = true;
        });
      }
      else {
        this.findingTypeArchived = false;
      }
    },

    fetchFindingType: function(callback) {
      app.graphql.get("details.findingType",
        {
          filter: {
            id: [this.findingTypeId]
          }
        },
        (data) => {
          let currentFindingType = data.data.findingTypes.edges[0].node;

          this.findingTypeName = currentFindingType.name;
          this.findingTypeDescription = currentFindingType.description;
          this.findingTypeArchived = currentFindingType.archived;
          this.findingTypeComponentType = currentFindingType.componentType;
          this.findingTypeComponentTypeId = currentFindingType.componentType.id;
          this.findingTypeFindingCount = currentFindingType.findingCount;
          
          this.findingTypeDetails = currentFindingType.details ? JSON.parse(currentFindingType.details) : [];

          this.findingTypeDetails.map((detail) => {
            detail.existing = this.findingTypeFindingCount > 0;

            if (detail.enum || detail.enumArchive) {   
              let enumOptions = []
              let enumArchiveOptions = []

              if (detail.enum) {
                enumOptions = detail.enum.map((opt, index) => {
                  return {
                    existing: detail.existing,
                    name: opt,
                    archived: false
                  }
                });

                delete detail.enum;
              }

              if (detail.enumArchive) {
                enumArchiveOptions = detail.enumArchive.map((opt, index) => {
                  return {
                    existing: detail.existing,
                    name: opt,
                    archived: true
                  }
                });

                delete detail.enumArchive;
              }

              detail.options = [...enumOptions, ...enumArchiveOptions];
            }

            return detail;
          });

          if (this.findingTypeFindingCount == 0) {
            nextTick(() => {
              this.$refs.componentTypes.updateValue(this.findingTypeComponentType);
            });
          }

          this.isLoaded = true;

          if (callback) {
            callback();
          }

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 1000);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch finding type!"
          });
        }
      );
    },

    fetchComponentTypes: function () {
      let fetchParams = {
        pageSize: 100,
        orderBy: {
          field: "NAME", 
          direction: "ASC"
        }
      };

      this.componentTypes = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.componentTypes",
          fetchParams,

          (data) => {
            let componentTypes = data.data.componentTypes,
                pageInfo = componentTypes.pageInfo;

            success(componentTypes, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch component types!"
            });
          }
        )
      };

      let getComponentTypes = (cursor) => {
        fetch(
          cursor,

          (componentTypes, pageInfo) => {
            componentTypes.edges.forEach((edge) => this.componentTypes.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getComponentTypes(pageInfo.endCursor))
            }
          }
        )
      };

      getComponentTypes(window.btoa(0));
    },
    
    setFindingTypeComponentType: function(opt) {
      if (opt) {
        this.findingTypeComponentTypeId = opt.id;
      }
      else {
        this.findingTypeComponentTypeId = null;
      }
    },
    
    submitForm: function() {
      let form = document.getElementsByTagName("form")[0];

      this.v$.findingTypeName.$touch();
      this.v$.findingTypeComponentTypeId.$touch();

      this.$refs.dataTypes.validate();

      let dataTypesAreValid = this.$refs.dataTypes.isValid;

      if (!this.v$.$error && dataTypesAreValid) {
        this.$refs.submitButton.loadingOn();
        
        form.submit();
      }
    }
  }
}

</script>
