<template>
  <div 
    :class="['stats-chart', css]"
  >
    <div 
      v-if="title"
      class="chart-header"
    >
      <h3 class="chart-title">{{ title }}</h3>
      <div class="chart-legend"></div>
    </div>
    <div class="chart-content">
      
      <div 
        class="columns-chart"
      >
        <div 
          v-for="(stat, index) in stats"
          :key="index"
          class="column" 
          :style="[{'--column-height': stat.height + '%'}, {'min-height': this.columnsMinHeight}]"
          :ref="'column-' + index"
          @mouseover="hoverAction(stat, $event)"
        ></div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  components: {
    
  },

  props: {
    title: String,
    data: Array,
    columnsMinHeight: String,
    css: {
      type: String,
      default: ''
    },
    relativeToHighestValue: {
      type: Boolean,
      default: true
    },
    showTooltips: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      stats: [],
      total: 0,
      highestValue: 0
    }
  },

  watch: {
    data: {
      handler(newValue, oldValue) {
        this.setData()
      }, 
      immediate: true
    }
  },

  methods: {
    setData: function() {
      this.data.forEach((r) => {
        this.total += r[1]

        this.highestValue = r[1] > this.highestValue ? r[1] : this.highestValue
      });

      this.stats = this.data.map((s) => {        
        return {
          description: s[0], 
          count: s[1],
          percentage: parseFloat((s[1] / this.total) * 100).toFixed(2),
          height: Math.round(s[1] / (this.relativeToHighestValue ? this.highestValue : this.total) * 100)
        }
      })
    },

    hoverAction: function(stat, e) {
      if (this.showTooltips) {
        const tooltip = new bootstrap.Tooltip(e.target, {
          title: stat.description + ': ' + stat.count + ' (' + stat.percentage + '%)',
          html: true,
          placement: 'top',
          container: 'body'
        })

        tooltip.show()
      }
    }
  }
}

</script>