<template>
<div class="data-type--option">
  <div class="option--separator">
    <div class="option--handler"></div>
  </div>
  <div class="option--form-control">
    <input 
      :class="['form-control', {'disabled': dataTypeArchived || archived}, {'error': v$.name.$error}]"
      type="text"
      placeholder="Option value"
      multiple="multiple"
      :readonly="existing"
      :name="formFieldName"
      :id="formFieldId"
      :value="name"
      @input="setName"
    />
  </div>
  <div class="option--actions">
    <div v-if="!dataTypeArchived">
      <template v-if="!archived">
        <button 
          type="button" 
          class="btn-expandable btn-expandable-default btn-expandable-sm"
          :title="removeDataTypeDetailOptionButtonLabel"
          @click="removeDataTypeDetailOption"
        >−</button>
      </template>
      <template v-else>
        <button
          type="button" 
          class="btn-expandable btn-expandable-delete btn-expandable-sm"
          title="Unarchive detail option"
          @click="unarchiveDataTypeDetailOption"
        ><span class="icon" data-icon="replay"></span></button>
      </template>
    </div>
  </div>
</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {

  },

  props: {
    index: Number,
    dataTypeIndex: Number,
    name: String,
    existing: Boolean,
    archived: Boolean,
    dataTypeArchived: Boolean,
    entityType: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      dataTypeDetailName: "",
    }
  },

  validations () {
    return {
      name: {
        required
      }
    }
  },

  mounted: function() {
    this.dataTypeDetailName = this.name
  },

  computed: {
    isValid: function() {
      this.validate();

      return !this.v$.name.$error
    },

    removeDataTypeDetailOptionButtonLabel: function() {
      return this.existing ? "Archive detail option" : "Remove detail option"
    },

    formFieldName: function(name) {
      return this.entityType + "[detail_schemas_attributes][" + this.dataTypeIndex + "][" + (this.archived ? 'enumArchive' : 'enum') + "][]"
    },

    formFieldId: function(id) {
      return this.entityType + "_detail_schemas_attributes_" + this.dataTypeIndex + "_" + (this.archived ? 'enumArchive' : 'enum')
    }
  },

  methods: {
    validate: function() {
      this.v$.name.$touch();

      this.$emit("validate-data-type-detail");
    },

    setName: function(event) {
      this.$emit("update", {
        index: this.index,
        name: event.target.value,
      });
    },

    removeDataTypeDetailOption: function() {      
      this.$emit("remove")
    },

    unarchiveDataTypeDetailOption: function() {
      this.$emit("update", {
        index: this.index,
        name: this.name,
        archived: false,
      });
    }
  }
};
</script>
