<template>
  <div 
    ref="map"
    v-bind:class="['map', 'pins-are-visible', {'spinner-is-visible': isSpinnerVisible}]"
  >
    <div class="spinner"></div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
  components: {

  },

  props: {
    googleMapApiKey: String,
    clientCompanyId: String  
  },

  data: function() {
    return {
      filter: {},
      sites: [],
      markers: [],
      google: null,
      mapPins: [],
      mapBounds: null,
      mapOptions: {
        panControl: true,
        zoomControl: true,
        mapTypeControl: true,
        streetViewControl: false,
        gestureHandling: "cooperative",
        scaleControl: false,
        rotateControl: false,
        zoom: 4,
        minZoom: 2,
        maxZoom: 20,
        center: { 
          lat: 39.5, 
          lng: -98.35 
        },
        mapId: this.clientCompanyId,
      },
      isSpinnerVisible: true,
      isInitialized: false,
      isHighRiskSitesMap: false,

      googleMap: null,
      googleMapCore: null,
      googleMapMarker: null
    }
  },

  watch: {
    isHighRiskSitesMap: function() {      
      if (this.isInitialized) {
        this.fetchSites();
      }
    },
  },

  created: function () {
    if (window.app && window.app.ui.state) {
      if (window.app.ui.state.activeTab == 'high-risk-sites') {
        this.isHighRiskSitesMap = true
      }
      if (window.app.ui.state.activeTab == 'sites') {
        this.isHighRiskSitesMap = false
      }
    }

    // Update map on ui refresh
    window.addEventListener("uirefresh", (event) => {
      if (window.app.ui.state.activeTab == 'high-risk-sites') {
        this.isHighRiskSitesMap = true
      }
      if (window.app.ui.state.activeTab == 'sites') {
        this.isHighRiskSitesMap = false
      }
    });
  },

  async mounted() {
    const loader = new Loader({
      apiKey: this.googleMapApiKey
    });

    this.googleMapCore = await loader.importLibrary("core");
    this.googleMapMarker = await loader.importLibrary("marker");
    this.googleMapMaps = await loader.importLibrary("maps");

    this.initializeMap();
  },

  methods: {
    initializeMap: function() {
      this.mapOptions.mapTypeControlOptions = {
        style: this.googleMapMaps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: this.googleMapCore.ControlPosition.TOP_RIGHT
      };

      this.mapOptions.mapTypeId = this.googleMapMaps.MapTypeId.HYBRID;

      this.mapOptions.zoomControlOptions = {
        position: this.googleMapCore.ControlPosition.RIGHT_BOTTOM
      };

      // Draw map
      const mapContainer = this.$refs.map;
  
      this.map = new this.googleMapMaps.Map(mapContainer, this.mapOptions);

      // Hide spinner on map load
      this.map.addListener("tilesloaded", () => {
        this.isSpinnerVisible = false;
      });

      this.isInitialized = true;

      this.fetchSites();
    },

    clearMap: function() {
      if (this.isInitialized) {
        this.mapBounds = new this.googleMapCore.LatLngBounds();

          this.mapPins.forEach((pin) => {          
          pin.setMap(null);
        });

        this.mapPins = [];
        this.markers = [];
      }
    },

    addSitesToMap: function() {
      const vmMap = this;

      // Check if ctrl or alt key is pressed in combination with mouse event
      let isCtrlALt = function(e) { 
        for(var i in e) {
          if(e[i] instanceof window.MouseEvent) {
            return e[i]["ctrlKey"] || e[i]["altKey"];
          }
        }
      }

      this.clearMap();

      // prepare markers
      this.sites.forEach((site) => {
        let marker = [];

        marker.push(site.name);
        marker.push("/sites/" + site.id);
        marker.push(site.latitude);
        marker.push(site.longitude);
        marker.push(site.latestSummary.clientRemediatedRating);
        marker.push(site.id);

        this.markers.push(marker);
      });
    
      // Add markers on map
      if (this.markers.length > 0) {
        this.markers.forEach((site, i) => {
          const mark = document.createElement("div");

          mark.className = "map-marker rating-" + site[4];
          mark.dataset.tooltip = site[0];
          mark.title = "Ctrl/Alt+Click for Site details page";
          
          const marker = new this.googleMapMarker.AdvancedMarkerElement({
            map: vmMap.map,
            position: { lat: site[2], lng: site[3] },
            content: mark,
          });

          marker.site = {
            id: site[5],
            url: site[1]
          }

          this.mapPins.push(marker);

          marker.addListener("click", (e) => {
            if (isCtrlALt(e)) {
              // Go to marker item page
              window.location.href = marker.site.url;
            }
            else {
              // Show marker item details in left sidebar details pane
              this.showSiteDetails(marker.site.id);
            }
          });

          this.mapBounds.extend(new this.googleMapCore.LatLng(site[2], site[3]));

          this.map.fitBounds(this.mapBounds);
        })
      }

      this.map.setCenter(this.mapBounds.getCenter());
    },

    showSiteDetails: function(siteId) {
      vmDetailsPane.$refs.detailsPane.open({
        title: "Site Details",
        entity: siteId,
        entityType: "siteDetails",
        trackHistory: false,
        args: null
      });

      app.ui.grid.markRow({
        grid: "clientCompanySites",
        row: siteId
      });
    },

    fetchSites: function() {
      let fetchParams = {
        pageSize: 200,
        filter: {
          clientCompanyId: this.clientCompanyId
        },
        includeCategory: true,
        includeStatistics: true
      };

      if (this.isHighRiskSitesMap) {
        fetchParams.filter.findings = { 
          rating: [4,5], 
          historical: false
        }
      }
      else {
        delete fetchParams.filter.findings
      }

      this.sites = [];

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.sites",
          fetchParams,

          (data) => {
            let sites = data.data.sites,
                pageInfo = sites.pageInfo;

            success(sites, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch site sites!"
            });
          }
        )
      };

      let getSites = (cursor) => {
        fetch(
          cursor,

          (sites, pageInfo) => {
            sites.edges.forEach((edge) => this.sites.push(edge.node));

            if (pageInfo.hasNextPage && pageInfo.endCursor) {
              Promise.resolve(getSites(pageInfo.endCursor));
            }
            
            if(!pageInfo.hasNextPage) {
              this.addSitesToMap();
            }
          }
        );
      };

      getSites(window.btoa(0));
    }
  }
}
</script>
