<template>
<div class="table-wrapper">
  <div
    class="table-responsive"
    v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
  >
    <table class="table">
      <thead>
        <tr>
          <SortableHeader
            class="text-start"
            :current-sort="orderBy"
            :sort-by="'CAPTION'"
            @sort="reorder"
          >Document</SortableHeader>

          <SortableHeader
            v-if="policyUpdate"
            class="text-center"
            style="width: 120px"
            :current-sort="orderBy"
            :sort-by="'INTERNAL'"
            @sort="reorder"
          >Visibility</SortableHeader>

          <SortableHeader
            v-if="showAssociation"
            class="text-start"
            :current-sort="orderBy"
            :sort-by="'DOCUMENTABLE_TYPE'"
            @sort="reorder"
          >Association</SortableHeader>

          <SortableHeader
            class="text-start"
            style="width: 100px"
            :current-sort="orderBy"
            :sort-by="'DOCUMENTABLE_TYPE'"
            @sort="reorder"
          >Type</SortableHeader>

          <SortableHeader
            class="text-end"
            style="width: 120px"
            :current-sort="orderBy"
            :sort-by="'UPDATED_AT'"
            @sort="reorder"
          >Date</SortableHeader>

          <th data-sorter="false" class="d-none d-md-table-cell"></th>
        </tr>
      </thead>

      <tbody>
        <Document
          v-for="(item, index) in items"
          v-bind="item"
          :key="item.id"
          :index="index"
          :policy-update="policyUpdate"
          :show-association="showAssociation"
          @remove-document="removeDocument"
        />
      </tbody>
    </table>

    <div class="spinner"></div>

    <NoRecords 
      label="According to the search criteria, there are no documents found."
    />

    <Pagination
      v-bind="pageInfo"
      entity-type="documents"
      @navigate-to="paginate"
    />
  </div>
</div>
</template>

<script>
import Grid from "../shared/grid/grid";

import Document from "./document";

export default {
  extends: Grid,

  components: {
    Document
  },

  props: {
    showClient: Boolean,
    policyUpdate: Boolean,
    entityId: String,
    entityType: String,
    showAssociation: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      items: [],

      grid: "documents",

      defaultOrderBy: {
        field: "CAPTION",
        direction: "ASC"
      },
      
      orderBy: {
        field: "CAPTION",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    filterParams: function () {
      return { 
        documentable: {
          type: this.entityType,
          id: this.entityId,
          strict: !this.showAssociation
        }
      }
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.documents",
        params,

        (data) => {
          let documents = data.data.documents,
              pageInfo = documents.pageInfo;

          success(documents, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch documents!"
          })
        }
      )
    },

    removeDocument: function(document) {
      var vm = this;
      
      app.remote.call({
        type: "DELETE",
        url: document,
        contentType: false,
        processData: false,
        success: function () {
            vm.refresh();

            app.ui.toast.add({
                priority: "success",
                title : "Documents",
                message : "Document was successfuly removed!"
            });
        }
      });
    },

    setDocumentAsInternal: function(caption, documentId, isInternal) {
      app.graphql.get(
        "update.document.toggleInternal",

        {
          input: {
            documentId: documentId,
            internal: isInternal
          }
        },

        (data) => {
          const response = data.data.toggleInternalDocument;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to update document",
                message: error
              });
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Document visibility",
              message: "Document <strong>" + caption + "</strong> visibility is set to <strong>" + (response.document.isInternal ? "internal" : "public") + "</strong>"
            });
          }
        }
      )
    } 
  }
}
</script>
