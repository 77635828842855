<template>
  <div
    :class="['entity-photo', {'loading' : !isLoaded && !isBrokenImage}, {'broken-image' : isBrokenImage}]"
  >
    <img
      v-if="!isBrokenImage"
      :ref=id
      :src="thumbSrc" 
      :title="caption"
      @click.prevent.stop="showGallery($event)"
    >

    <svg
      v-if="isBrokenImage" 
      width="30" viewBox="0 0 137 98" fill="none" xmlns="http://www.w3.org/2000/svg" style="position: absolute">
      <path d="M81.0001 91.9627L117.076 97.0849C120.649 97.5923 123.988 95.0836 124.496 91.5101L135.566 13.542C136.073 9.96851 133.565 6.62958 129.991 6.12219L93.9153 1L92.9928 7.49733L129.057 12.6179C129.06 12.6219 129.064 12.6267 129.067 12.6313L118 90.576C117.997 90.5782 117.995 90.5805 117.992 90.5826L117.986 90.5861L81.9225 85.4656L81.0001 91.9627Z" fill="#dddddd"/>
      <path d="M82.8451 78.968L112.423 83.1677L117.959 44.1837L92.4504 60.4469L85.953 59.5243L85.6731 59.0506L82.8451 78.968Z" fill="#dddddd"/>
      <path d="M113.768 27.0178C113.003 32.4005 108.02 36.1443 102.638 35.38C97.2551 34.6158 93.5113 29.6329 94.2756 24.2503C95.0398 18.8676 100.023 15.1238 105.405 15.888C110.788 16.6523 114.532 21.6352 113.768 27.0178Z" fill="#dddddd"/>
      <path d="M62.4498 0.318589L7.04725 2.28897C3.44015 2.41726 0.593853 5.47348 0.722138 9.08057L3.5211 87.7808C3.64939 91.3879 6.70561 94.2342 10.3127 94.1059L65.7152 92.1355L65.482 85.5774L10.0911 87.5474C10.0869 87.544 10.0825 87.5399 10.079 87.5359L7.28091 8.85901C7.28425 8.85478 7.2884 8.85033 7.29217 8.84691L62.683 6.87694L62.4498 0.318589Z" fill="#dddddd"/>
      <path d="M64.5337 58.9144L37.4928 27.4728L15.9381 67.6393L16.4046 80.756L65.2487 79.0188L64.5337 58.9144Z" fill="#dddddd"/>
    </svg>
  </div>
  </template>
  
  <script>  
  export default {
    props: {
      index: Number,
      id: String,
      imageUrl: String,
      thumbUrl: String,
      previewUrl: String,
      sourceUrl: String,
      caption: String,
      gallery: String,
      galleryPhotos: Object,
      userCanUpdate: Boolean,
      entityType: {
        type: String,
        default: "finding"
      }
    },
  
    data() {
      return {
        imageSrc: this.imageUrl,
        thumbSrc: this.thumbUrl,
        previewSrc: this.previewUrl,
        sourceSrc: this.sourceUrl,
        isLoaded: false,
        isFallbackImage: false,
        isBrokenImage : false,
        refreshInterval: 15000, //15sec
        refreshAtemptsLimit: 12, //3min
        refreshAtempts: 0,
        galleryItems: [],
        galleryIndex: 0
      };
    },

    watch: {
      galleryPhotos: {
        handler(newValue, oldValue) {    
          let vm = this;

          this.galleryItems = [...new Set(Array.from(vm.galleryPhotos, (v) => [`${v.caption}`, `${v.previewUrl}`, `${v.imageUrl}`, `${v.sourceUrl}`, `${v.id}`]))];
        
          this.galleryIndex = vm.galleryItems.findIndex(item => item[4] === vm.id);
        }, 
        immediate: true
      }
    },
  
    mounted: function() {
      let vm = this;
      let photo = this.$refs[this.id];

      photo.addEventListener('load', function () {
        let img = this;

        // detect upladed photo in processing
        vm.isFallbackImage = img.src.includes('assets/carrierwave_fallback')
        vm.isLoaded = !vm.isFallbackImage
        vm.isBrokenImage = false

        if (vm.isFallbackImage) {
          vm.fetchPhoto()
        }
        else {
          vm.loadImage();
        }
      })

      photo.addEventListener('error', function () {
        vm.isLoaded = false

        if (vm.refreshAtempts >= vm.refreshAtemptsLimit) {
          vm.isBrokenImage = true
        }

        vm.loadImage();
      })
    },
  
    methods: {
      loadImage: function() {
        let vm = this;
        let photo = this.$refs[this.id];

        this.$emit('photo-load', {
          index: this.index, 
          id: this.id, 
          imageUrl: this.imageSrc,
          thumbUrl: this.thumbSrc,
          previewUrl: this.previewSrc,
          sourceUrl: this.sourceSrc,
          isLoaded: this.isLoaded
        });

        window.clearInterval(window['loadPhoto-' + this.id])

        this.refreshAtempts++

        if (!this.isLoaded && this.refreshAtempts <= this.refreshAtemptsLimit) {
          window['loadPhoto-' + this.id] = window.setInterval(function() {
            photo.src = vm.thumbUrl
          }, this.refreshInterval)
        }
      },

      fetchPhoto: function() {
        let vm = this;

        app.graphql.get(
          "details." + this.entityType + "Photos",

          {
            filter: {
              id: this.id
            }
          },

          (data) => {
            const photo = data.data[this.entityType + "Photos"].edges[0].node;

            vm.imageSrc = photo.imageUrl
            vm.thumbSrc = photo.thumbUrl
            vm.previewSrc = photo.previewUrl
            vm.sourceSrc = photo.sourceUrl

            vm.loadImage()
          }
        );
      },

      showGallery: function(e) {        
        if (this.isLoaded) {
          vmModal.$refs.modal.show({
            template: 'photoGallery',
            style: {
              class: 'media-gallery-modal',
              verticalyCentered: true
            },
            args: {
              userCanUpdate: this.userCanUpdate,
              entityType: this.entityType,
              gallery: this.gallery,
              galleryItems: this.galleryItems,
              currentItem: this.galleryIndex
            }
          })
        }
      }
    }
  };
  </script>