<template>
  <div class="modal-body pt-0">
    <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
      <div v-bind:class="{'hidden': !isLoaded }">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label class="placeholder placeholder-inactive">Remote Fulcrum Forms</label>
              <v-select
                placeholder="Select Form"
                ref="fulcrumForms"
                :class="[{'vs-error': v$.fulcrumFormId.$error}]"
                :options="fulcrumForms"
                label="name"
                @update:modelValue="setFulcrumForm"
              />
              <div v-if="v$.fulcrumFormId.$error" class="error">Fulcrum Form is required</div>
            </div>
          </div>
        </div>
                
        <div class="form-group-button">
          <form-button
            ref="submitButton"
            label="Import Form"
            @submit="importApp"
          ></form-button>
        </div>
      </div>
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators';

export default {
  components: {
    formButton,
    vSelect
  },

  props: {
    args: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      fulcrumForms: [],
      fulcrumFormId: '',
    }
  },

  validations () {
    return {
      fulcrumFormId: {
        required
      }
    }
  },

  mounted: function() {
    this.fetchFulcrumForms();
  },

  methods: {
    setFulcrumForm: function(opt) {
      this.fulcrumFormId  = (opt) ? opt.formId : '';
    },

    fetchFulcrumForms: function () {
      app.graphql.get("select.fulcrumAppsRemote",
        {
          filter: {
            excludeSynced: true
          }
        },

        (data) => {
          this.fulcrumForms = data.data.remoteFulcrumApps

          this.isLoaded = true;

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 1000);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch Fulcrum Apps!"
          });
        }
      )
    },

    importApp: function() {
      this.v$.fulcrumFormId.$touch();

      if (this.v$.$errors.length <= 0) {
        let vm = this;

        this.$refs.submitButton.loadingOn();

        app.graphql.get(
          "create.fulcrumApp",

          {
            input: {
              formId: vm.fulcrumFormId
            }
          },

          (data) => {
            const response = data.data.fetchFulcrumApp;

            if (response.errors) {
              response.errors.forEach((error) => {
                app.ui.toast.add({
                  priority: "warning",
                  title: "Unable to import remote Fulcrum Form",
                  message: error
                })
              })
            } else {
              app.ui.toast.add({
                priority: "success",
                title: "Fulcrum Form Import",
                message: "Fulcrum Form <strong>" + response.fulcrumApp.name + "</strong> was successfully imported!"
              })

              if (vmFulcrumApps && vmFulcrumApps.$refs.fulcrumApps) {
                vmFulcrumApps.$refs.fulcrumApps.refresh();
              }

              vmModal.$refs.modal.hide();
            }
          }
        );
      }
    }
  }
}

</script>