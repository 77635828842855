<template>
  <div class="table-wrapper">
    <div
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              class="text-start"
              :current-sort="orderBy"
              :sort-by="'NAME'"
              @sort="reorder"
            >Site</SortableHeader>

            <th
              v-if="showStatus"
              class="text-end"
              style="width: 200px"
            >Findings</th>

            <th data-sorter="false" class="d-none d-md-table-cell"></th>
          </tr>
        </thead>

        <tbody>
          <Site
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :policy-update="policyUpdate"
            :show-status="showStatus"
            :project-id="projectId"
            :ref="item.id"
            :selected="isItemMarked(item.id)"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords
        label="According to the search criteria, there are no related sites found."
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="sites"
        @navigate-to="paginate"
      />
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import Site from "./site";

export default {
  extends: Grid,

  components: {
    Site
  },

  props: {
    projectId: String,
    showStatus: Boolean
  },

  data: function () {
    return {
      items: [],

      grid: "project-sites",

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },

      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    filterParams: function () {
      let params = {
        projectId: this.projectId
      };

      return params;
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.project.sites",
        params,

        (data) => {
          let sites = data.data.sites,
              pageInfo = sites.pageInfo;

          success(sites, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch sites!"
          })
        }
      )
    }
  }
}
</script>
