<template>
  <div class="table-wrapper">
    <div
      class="table-responsive"
      :class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'NAME'"
              @sort="reorder"
            >
              Name
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'REPORT_TYPE'"
              @sort="reorder"
            >
              Report Type
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'CREATED_AT'"
              @sort="reorder"
            >
              Created
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'DATE'"
              @sort="reorder"
            >
              Last Ran
            </SortableHeader>

            <th data-sorter="false" class="d-none d-md-table-cell"></th>
          </tr>
        </thead>

        <tbody>
          <Report
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            @update-report="updateReport"
            @delete-report="deleteReport"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords
        label="No matching reports found"
      />

      <Pagination
        v-if="pageInfo.totalPageCount > 1"
        v-bind="pageInfo"
        @navigate-to="paginate"
      />
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";
import SortableHeader from "../shared/grid/sortable_header";
import Report from "./report";
import Pagination from "../shared/pagination";

export default {
  extends: Grid,

  components: {
    SortableHeader,
    Report,
    Pagination
  },

  data: function () {
    return {
      items: [],

      grid: "downloadable-reports",

      defaultOrderBy: {
        field: "DATE",
        direction: "DESC"
      },

      orderBy: {
        field: "DATE",
        direction: "DESC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true,

      refreshTimer: null
    };
  },

  computed: {
    pendingIds: function () {
      return this.pendingReports.map(r => r.id);
    },

    pendingReports: function () {
      return this.items.filter(r => !r.date);
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.downloadableReports",
        params,

        (data) => {
          const reports = data.data.downloadableReports;

          success(reports);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch reports!"
          });
        }
      );
    },

    autoRefresh: function () {
      if (!this.refreshTimer) {
        this.refreshTimer = setInterval(this.refreshPending, 5000);
      }
    },

    updateReport: function (report) {
      const oldReport = this.items.find(r => r.id === report.id);

      if (!oldReport.date && report.date) {
        app.ui.toast.add({
          priority: "success",
          title: "Report generated!",
          message: report.name + " is now available for download."
        });
      }

      Object.assign(oldReport, report);

      if (!report.date && !this.refreshTimer) {
        this.autoRefresh();
      }
    },

    deleteReport: function (reportId) {
      const i = this.items.findIndex(r => r.id === reportId);

      if (i > -1) {
        this.items.splice(i, 1);
      }
    },

    refreshPending: function () {
      if (this.pendingReports.length > 0) {
        app.graphql.get("grid.downloadableReports",
          {
            filter: {
              id: this.pendingIds,
            },
          },

          (data) => {
            const reports = data.data.downloadableReports
              .edges.map(edge => edge.node);

            reports.forEach(this.updateReport);
          }
        );
      } else if (this.refreshTimer) {
        clearInterval(this.refreshTimer);
        this.refreshTimer = null;
      }
    }
  },

  mounted: function () {
    this.autoRefresh();
  },

  beforeDestroy: function () {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  },
}
</script>
