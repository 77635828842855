<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 300px;">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <legend>Physical Asset type</legend>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="physical_asset_type_name" class="placeholder placeholder-static">Name</label>
                  <input 
                    :class="['form-control', {'error': v$.physicalAssetTypeName.$error}]"
                    placeholder="Physical Asset type name" 
                    type="text" 
                    name="physical_asset_type[name]" 
                    id="physical_asset_type_name"
                    v-model.trim="physicalAssetTypeName"
                    @keydown.enter.prevent="submitForm"
                  />
                  <div v-if="v$.physicalAssetTypeName.$error" class="error">Physical Asset type name is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-static" for="physical_asset_type_description">Description</label>
                  <textarea 
                    rows="4" 
                    class="form-control"
                    placeholder="Physical Asset type description" 
                    name="physical_asset_type[description]" 
                    id="physical_asset_type_description"
                    v-model="physicalAssetTypeDescription"
                  ></textarea>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-lg-6">
          <fieldset>
            <legend>Physical Asset type Details</legend>
            <form-data-types
              ref="dataTypes"
              :default-data-types="physicalAssetTypeDetails"
              entity-type="physical_asset_type"
            />
          </fieldset>
        </div>
      </div>
      <div class="form-group">
        <form-button
          ref="submitButton"
          :label="submitButtonLabel"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import vSelect from "vue-select";

import formDataTypes from "../shared/data_types/form_data_types";
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
    formDataTypes,
    formButton
  },

  props: {
    formId: String,
    physicalAssetTypeId: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      physicalAssetTypeName: "",
      physicalAssetTypeDescription: "",
      physicalAssetTypeDetails: null,
      isLoaded: false,
      isSpinnerVisible: true,
    }
  },

  validations () {
    return {
      physicalAssetTypeName: {
        required
      }
    }
  },

  computed: {
    submitButtonLabel: function() {
      return (this.isExistingphysicalAssetType) ? "Update Physical Asset type" : "Create Physical Asset type";
    },

    isExistingphysicalAssetType: function() {
      return this.physicalAssetTypeId != "";
    }
  },

  mounted: function() {
    if (this.isExistingphysicalAssetType) {
      this.fetchphysicalAssetType();
    }
    else {
      this.isLoaded = true;

      setTimeout(() => {
        this.isSpinnerVisible = false;
      }, 1000);
    }
  },

  methods: {
    fetchphysicalAssetType: function(callback) {
      app.graphql.get("details.assetType",
        {
          filter: {
            id: [this.physicalAssetTypeId]
          }
        },
        (data) => {
          let currentphysicalAssetType = data.data.physicalAssetTypes.edges[0].node;

          this.physicalAssetTypeName = currentphysicalAssetType.name;
          this.physicalAssetTypeDescription = currentphysicalAssetType.description;
          
          this.physicalAssetTypeDetails = currentphysicalAssetType.details ? JSON.parse(currentphysicalAssetType.details) : [];

          this.physicalAssetTypeDetails.map((detail) => {
            if (detail.enum) {   
              let enumOptions = []

              if (detail.enum) {
                enumOptions = detail.enum.map((opt, index) => {
                  return {
                    name: opt
                  }
                });

                delete detail.enum;
              }

              detail.options = [...enumOptions];
            }

            return detail;
          });

          this.isLoaded = true;

          if (callback) {
            callback();
          }

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 1000);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch Physical Asset type!"
          });
        }
      );
    },
    
    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.physicalAssetTypeName.$touch();

      this.$refs.dataTypes.validate();

      let dataTypesAreValid = this.$refs.dataTypes.isValid;

      if ((!this.v$.$error) && dataTypesAreValid) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>
