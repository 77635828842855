<template>
  <div class="table-wrapper">
    <div class="row">
      <div class="col-md-6 col-sm-12 grid-search-control">
        <SearchField
          v-model:textSearch="textSearch"
          placeholder="Search for revisions by name, finding description, site, asset, component or revised by"
          @input="isSpinnerVisible = true"
          @search="search"
        />
      </div>
    </div>

    <div
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >

      <table class="table findings-revisions-list">
        <thead>
          <tr>
            <SortableHeader
              class="text-center"
              style="width: 60px;"
              :current-sort="orderBy"
              :sort-by="'REVISED_RATING'"
              @sort="reorder"
            >
              Rating
            </SortableHeader>

            <SortableHeader
              class="text-center"
              :current-sort="orderBy"
              :sort-by="'ORIGINAL_RATING'"
              @sort="reorder"
            >
              Original rating
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'FINDING_DISPLAY_NAME'"
              @sort="reorder"
            >
              Finding
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'CATEGORY'"
              @sort="reorder"
            >
              Revision
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'SITE_NAME'"
              @sort="reorder"
            >
              Site
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'PHYSICAL_ASSET_NAME'"
              @sort="reorder"
            >
              Asset
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'COMPONENT_NAME'"
              @sort="reorder"
            >
              Component
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'REVISED_BY'"
              @sort="reorder"
            >
              Revised By
            </SortableHeader>

            <SortableHeader
              class="text-end"
              style="width: 120px"
              :current-sort="orderBy"
              :sort-by="'CREATED_AT'"
              @sort="reorder"
            >Date</SortableHeader>

            <th data-sorter="false" class="d-none d-md-table-cell"></th>
          </tr>
        </thead>

        <tbody>
          <Revision
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :policy-update="policyUpdate"
            :project-id="projectId"
            :ref="item.id"
            :selected="isItemMarked(item.id)"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords
        label="According to the search criteria, there is no related revisions"
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="revisions"
        @navigate-to="paginate"
      />

    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import Revision from "./revision"

export default {
  extends: Grid,

  components: {
    Revision
  },

  props: {
    projectId: String
  },

  data: function () {
    return {
      items: [],

      grid: "project-revisions",

      textSearch: '',

      defaultOrderBy: {
        field: "CREATED_AT",
        direction: "DESC"
      },

      orderBy: {
        field: "CREATED_AT",
        direction: "DESC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    filterParams: function () {
      let params = {
        projectId: this.projectId,
        revisionsOnly: true,
        event: "UPDATE"
      }

      if (this.textSearch) {
        params.text = {
          value: this.textSearch,
          fields: [
            "DESCRIPTION",
            "FINDING_DESCRIPTION_DETAIL", 
            "SITE_NAME", 
            "PHYSICAL_ASSET_NAME", 
            "COMPONENT_NAME",
            "REVISED_BY_NAME"
          ]
        }
      }

      return params
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.project.findingVersions",
        params,

        (data) => {
          let findings = data.data.findingVersions
          let pageInfo = findings ? findings.pageInfo : {}

          success(findings, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch revisions!"
          })
        }
      )
    }
  }
}
</script>
