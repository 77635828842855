<template>
  <div 
    :class="['stats-chart', css]"
  >
    <div 
      v-if="title"
      class="chart-header"
    >
      <h3 class="chart-title">{{ title }}: <strong>{{ total }}</strong></h3>
      <div class="chart-legend"></div>
    </div>
    <div class="chart-content">
      
      <div 
        class="line-chart"
        ref="chartContainer"
      >
        <svg 
          version="1.2" 
          xmlns="http://www.w3.org/2000/svg" 
          xmlns:xlink="http://www.w3.org/1999/xlink" 
          class="graph" 
          aria-labelledby="title" 
          role="img"
          :height="canvasHeight"
          :width="canvasWidth"
        >          
          <g v-if="showGridXLine" class="grid x-grid" id="xGrid">
            <line :x1="xGridX" :x2="xGridX" :y1="offsetTop" :y2="yGridY" vector-effect="non-scaling-stroke"></line>
          </g>

          <g class="grid y-grid" id="yGrid">
            <line :x1="yGridLineX" :x2="canvasWidth" :y1="yGridY" :y2="yGridY" vector-effect="non-scaling-stroke"></line>
          </g>

          <g class="labels y-labels">
            <text 
              v-for="(label, index) in yLabels.labels"
              :key="index"
              :x="yLabelX" 
              :y="yLabelY(index)"
            >{{ label }}</text>
          </g>
          
          <g v-if="showXlabels" class="labels x-labels">
            <text 
              v-for="(label, index) in xLabels.labels"
              :key="index"
              :x="xLabelX(index)" 
              :y="xLabelY"
            >{{ label }}</text>
          </g>
          
          <g class="grid y-lines">
            <line 
              v-for="(label, index) in yLabels.labels.slice(0, -1)"
              :key="index"
              :x1="yGridLineX" 
              :x2="canvasWidth" 
              :y1="yLineY(index)"
              :y2="yLineY(index)"
              vector-effect="non-scaling-stroke"
            ></line>
          </g>

          <g class="graph-line">
            <path class="line" :d="path" stroke="#ccc" style="display:block" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="" fill="none"></path>

            <circle 
              v-for="(point, index) in points"
              :key="index"
              :cx="point.x" 
              :cy="point.y"
              r="4"
              :ref="'point-' + index"
            ></circle>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    yLabels: Object,
    xLabels: Object,
    data: Array,
    width: {
      type: Number,
      default: 700
    },
    height: {
      type: Number,
      default: 150
    },
    css: {
      type: String,
      default: ''
    },
    offsetBottom: {
      type: Number,
      default: 0
    },
    offsetTop: {
      type: Number,
      default: 0
    },
    offsetLeft: {
      type: Number,
      default: 0
    },
    offsetRight: {
      type: Number,
      default: 0
    },
    labelsFontSize: {
      type: Number,
      default: 10
    },
    showTooltips: {
      type: Boolean,
      default: true
    },
    showGridXLine: {
      type: Boolean,
      default: true
    },
    showXlabels: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      canvasWidth: this.width,
      canvasHeight: this.height,
      path: '',
      points: [],
      total: 0,
      highestValue: 0,
      tooltip: null
    }
  },

  watch: {
    data: {
      handler(newValue, oldValue) {        
        this.setData()
      }, 
      immediate: true
    }
  },
  
  mounted: function() {
    let vm = this;

    this.canvasWidth = this.$refs.chartContainer.offsetWidth;

    function debounce(func, delay) {
      let timer;
      return function (...args) {
          clearTimeout(timer);
          timer = setTimeout(() => func.apply(this, args), delay);
      };
    }
    
    window.addEventListener('resize', debounce(() => {
      vm.canvasWidth = vm.$refs.chartContainer.offsetWidth;

      vm.generatePath()
    }, 100));
  },

  computed: {
    yLabelX: function() {
      return this.yLabels.offsetLeft + this.yLabels.labelWidth;
    },
    xGridX: function() {
      return this.yLabels.offsetLeft + this.yLabels.labelWidth + this.yLabels.offsetRight;
    },
    yGridLineX: function() {
      return this.yLabels.offsetLeft + this.yLabels.labelWidth + this.yLabels.offsetRight;
    },
    xLabelWidth: function() {
      return (this.canvasWidth - this.yGridLineX) / this.xLabels.labels.length
    },
    xLabelY: function() {
      return this.canvasHeight - this.xLabels.offsetBottom;
    },
    yGridY: function() {
      return this.showXlabels ? (this.canvasHeight - this.xLabels.offsetBottom - this.labelsFontSize - this.xLabels.offsetTop) : (this.canvasHeight - this.labelsFontSize / 2);
    },
  },

  methods: {
    xLabelX: function(i) {
      let firstXLabelX = this.yGridLineX + (this.xLabelWidth / 2)
      let xLabelX = firstXLabelX

      if (i > 0) {
        xLabelX = firstXLabelX + (this.xLabelWidth * i)
      }

      return xLabelX
    },

    yLabelY: function(i) {
      const firstY = this.offsetTop + this.labelsFontSize
      const lastY = this.yGridY;
      
      const labelYSpan = (lastY - (this.offsetTop + (this.labelsFontSize / 2))) / (this.yLabels.labels.length - 1)

      let labelY = firstY

      if (i == 0) {
        labelY = firstY
      }
      else if (this.showXlabels && i == this.yLabels.labels.length-1) {
        labelY = lastY
      }
      else {
        labelY = labelY + (labelYSpan * (i))
      }

      return labelY
    },

    yLineY: function(i) {
      const firstY = this.offsetTop + (this.labelsFontSize / 2)
      
      const lineYSpan = (this.yGridY - (this.offsetTop + (this.labelsFontSize / 2))) / (this.yLabels.labels.length - 1)

      if (i == 0) {
        return firstY
      }
      else if (i < this.yLabels.labels.length - 1) {
        return firstY + (lineYSpan * (i))
      }
    },

    mapValuesToRange: function(minSource, maxSource, minTarget, maxTarget) {
      return this.data.map(item => 
        Math.round(minTarget + (item.value - minSource) * (maxTarget - minTarget) / (maxSource - minSource))   
      )
    },

    generatePath: function() {
      let valuesRanges = this.mapValuesToRange(this.yLabels.labels[0], this.yLabels.labels[this.yLabels.labels.length-1], this.yLineY(0), this.yGridY)

      this.points = valuesRanges.map((y, index) => {
        return { 
          x: this.xLabelX(index), 
          y: y 
        }
      })

      let path = 'M0 0';

      if (this.points.length > 0) {
        path = `M${this.points.map(p => `${p.x},${p.y}`).join("L")}`;
      }

      this.path = path;

      this.$nextTick(() => {
        this.handleTooltips()
      });
    },

    handleTooltips: function() {
      if (this.showTooltips) {
        const vm = this;
        
        this.points.forEach((point, index) => {
          const p = this.$refs['point-' + index];

          const t = new bootstrap.Tooltip(p[0], {
            title: this.data[index].tooltip,
            html: true,
            placement: 'top',
            trigger : 'hover'
          })
        })
      }
    },

    setData: function() {
      this.generatePath()
    }
  }
}

</script>