<template>
  <div class="table-wrapper">
    <div 
      v-if="policyUpdate"
      class="d-flex align-items-center justify-content-between"
    >
      <div>
        <remediations-status-label
          ref="remediationStatus"
          :inspection-id="inspectionId"
          :show-remediation-status="true"
          :load-on-init="loadOnInit"
          @update="handleRemediationsStatusButton"
        />
      </div>
      <div>
        <div>
          <remediations-reviewal-button
            v-if="showRemediationsReviewalButton"
            :inspection-id="inspectionId"
            text="Review"
            title="Review All In Draft Remediations"
            confirm="All Activity In Draft Remediations will be reviewed?"
            size="sm"
            @reviewed="refreshGrid"
          />
          <remediations-approval-button
            v-if="showRemediationsApprovalButton"
            :inspection-id="inspectionId"
            text="Approve"
            title="Approve All In Review Remediations"
            confirm="All Activity In Review Remediations will be approved?"
            size="sm"
            @approved="refreshGrid"
          />
          <a 
            :href="addRemediationUrl" 
            title="Add New Remediation" 
            class="btn-expandable btn-expandable-add btn-expandable-sm mr-0">
              <span class="icon" data-icon="add"></span>
          </a>
        </div>
      </div>
    </div>
    <div 
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >

      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              class="text-center"
              style="width: 100px"
              :current-sort="orderBy"
              :sort-by="'RATING'"
              @sort="reorder"
            >Rating</SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'REMEDIATION_TYPE_NAME'"
              @sort="reorder"
            >Remediation</SortableHeader>

            <SortableHeader
              style="min-width: 150px; max-width: 200px"
              :current-sort="orderBy"
              :sort-by="'PHYSICAL_ASSET_NAME'"
              @sort="reorder"
            >Asset</SortableHeader>

            <SortableHeader
              class="text-center"
              style="width: 100px"
              v-if="showStatus"
              :current-sort="orderBy"
              :sort-by="'STATUS'"
              @sort="reorder"
            >Status</SortableHeader>

            <th 
              class="text-center"
              style="width: 100px"
            >Images</th>
            <th data-sorter="false" class="d-none d-md-table-cell"></th>
          </tr>
        </thead>

        <tbody>
          <Remediation
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :show-status="showStatus"
            :policy-update="policyUpdate"
            @update-status="refreshGrid"
            :ref="item.id"
            :selected="isItemMarked(item.id)"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords 
        label="There is no related remediations"
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="remediations"
        @navigate-to="paginate"
      />
      
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";
import Remediation from "./remediation"
import RemediationsStatusLabel from "./inspection_status_label";
import RemediationsApprovalButton from "../remediations/remediations_approval_button";
import RemediationsReviewalButton from "../remediations/remediations_reviewal_button";

export default {
  extends: Grid,

  components: {
    Remediation,
    RemediationsStatusLabel,
    RemediationsApprovalButton,
    RemediationsReviewalButton
  },

  props: {
    showStatus: Boolean,
    inspectionId: String
  },

  data: function () {
    return {
      items: [],

      grid: "remediations",

      defaultOrderBy: {
        field: "RATING",
        direction: "DESC"
      },

      orderBy: {
        field: "RATING",
        direction: "DESC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true,

      showRemediationsApprovalButton: false,
      showRemediationsReviewalButton: false
    }
  },

  watch: {
    shouldLoad: function() {
      if (this.shouldLoad) {
        const statusLabel = this.$refs.remediationStatus;
        
        if (statusLabel) {
          statusLabel.shouldLoad = true;
          statusLabel.refresh();
        }
      }
    }
  },

  computed: {
    filterParams: function () {
      return { inspectionId: this.inspectionId }
    },

    addRemediationUrl: function () {
      return "/remediations/new?inspection_id=" + this.inspectionId;
    },
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.remediations",
        params,

        (data) => {
          let remediations = data.data.remediations,
              pageInfo = remediations.pageInfo;

          success(remediations, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch related remediations!"
          })
        }
      )
    },

    handleRemediationsStatusButton: function (obj) {
      this.showRemediationsApprovalButton = obj.remediationsInReview > 0;
      this.showRemediationsReviewalButton = obj.remediationsInDraft > 0;
    },

    refreshGrid: function() {
      this.refresh();
      this.$refs.remediationStatus.refresh();
    }
  }
}
</script>
