<template>
  <div class="table-responsive">
    <table
      class="table"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <thead>
        <tr>
          <SortableHeader
            :current-sort="orderBy"
            :sort-by="'NAME'"
            @sort="reorder"
          >Inspector</SortableHeader>

          <th
            style="width: 120px"
          >Created At</th>

          <SortableHeader
            style="width: 120px"
            class="text-center"
            :current-sort="orderBy"
            :sort-by="'ACTIVE'"
            @sort="reorder"
          >Status</SortableHeader>

          <th data-sorter="false" class="d-none d-md-table-cell"></th>
        </tr>
      </thead>

      <tbody>
        <Inspector
          v-for="(item, index) in items"
          v-bind="item"
          :key="item.id"
          :index="index"
          :policy-update="policyUpdate"
        />
      </tbody>
      <div class="spinner"></div>
    </table>

    <Pagination
      v-bind="pageInfo"
      entity-type="inspectors"
      @navigate-to="paginate"
    />
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import Inspector from "./inspector";

export default {
  extends: Grid,

  components: {
    Inspector
  },

  data: function () {
    return {
      items: [],

      grid: "qr-inspectors",

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },

      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 50,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.inspectors",
        params,

        (data) => {
          let inspectors = data.data.inspectors,
              pageInfo = inspectors.pageInfo;

          success(inspectors, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch Inspectors!"
          })
        }
      )
    },

    update: function (inspectorInfo) {
      let inspector = this.items.find(function (item) {
        return item.id === inspectorInfo.id;
      })

      inspector.name = inspectorInfo.name;
    }
  }
}
</script>
