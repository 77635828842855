<template>
  <div class="table-responsive">
    <table
      class="table"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <thead>
        <tr>
          <SortableHeader
            :current-sort="orderBy"
            :sort-by="'NAME'"
            @sort="reorder"
          >Form Name</SortableHeader>

          <th
            class="text-end"
            style="width: 180px"
          >Last Synced</th>
        </tr>
      </thead>

      <tbody>
        <FulcrumApp
          v-for="(item, index) in items"
          v-bind="item"
          :key="item.id"
          :index="index"
          :policy-update="policyUpdate"
        />
      </tbody>
      <div class="spinner"></div>
    </table>

    <Pagination
      v-bind="pageInfo"
      entity-type="inspectors"
      @navigate-to="paginate"
    />
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import FulcrumApp from "./fulcrum_app";

export default {
  extends: Grid,

  components: {
    FulcrumApp
  },

  data: function () {
    return {
      items: [],

      grid: "fulcrum-apps",

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },

      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 50,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.fulcrumApps",
        params,

        (data) => {
          let fulcrumApps = data.data.fulcrumApps,
              pageInfo = fulcrumApps.pageInfo;

          success(fulcrumApps, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch Fulcrum Apps!"
          })
        }
      )
    }
  }
}
</script>
