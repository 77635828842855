<template>
  <div v-bind:class="[{'spinner-is-visible': isSpinnerVisible }]">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="modal-header">
        <div class="modal-header-heading">
            <DamageRating 
              :rating="site.latestSummary.clientRemediatedRating" 
            />
            <a
              :href="'/sites/' + site.id"
              tile="Site"
            >{{site.name}}</a>
        </div>
        <div class="modal-header-action-group">
          <a title="Site" class="btn-expandable btn-expandable-sm btn-expandable-preview" :href="'/sites/' + site.id">
            <span class="icon" data-icon="fullscreen"></span>
            <span>Full Site Details</span>
          </a>
          <a title="Site Edit" class="btn-expandable btn-expandable-sm btn-expandable-edit" :href="'/sites/' + site.id + '/edit'">
            <span class="icon" data-icon="edit"></span>
          </a>
          <button type="button" class="btn-close" data-dismiss="modal" @click="hideModal"></button>
        </div>
      </div>
      <div class="modal-body">
        <div 
          v-if="hasStats"
        >
          <div 
            v-if="timelineStats.length < 0"
            class="row"
          >
            <div class="col-sm-12">
              <Timeline
                ref="siteActivitiesTimeline"
                title="Site Inspections Through Time"
                css="site-activities-timeline"
                :data="timelineStats"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <RowChart
                v-if="hasDefectTypeFrequencyStats"
                ref="ratingFrequencyBarsChart"
                title="Finding Frequency"
                css="rating-frequency-chart"
                :data="ratingFrequencyStats"
                bars-min-width="5px"
              />

              <RowChart
                v-if="hasWorstCaseDamageFrequencyStats"
                ref="worstCaseDamageFrequencyBarsChart"
                title="Asset Rating Frequency"
                css="worst-case-damage-frequency-chart"
                :data="worstCaseDamageFrequencyStats"
                bars-min-width="5px"
              />

              <div 
                v-if="hasComponentWorstCaseDamageCountsStats && !isWindTurbine"
                class="multi-row-chart component-worst-case-damage-chart"
              >
                <div class="chart-header">
                  <h3 class="chart-title">Component Damage Distribution: <strong>{{ componentWorstCaseDamageCountsTotal }}</strong></h3>
                </div>
                <div class="chart-content">
                  <RowChart 
                    v-for="(componentStats, index) in componentWorstCaseDamageCountsStats"
                    :key="index"
                    :ref="'componentWorstCaseDamageChartRow' + index"
                    :title="componentStats[0]"
                    :data="componentStats[2]"
                    :include-total="true"
                    :include-counts="true"
                    bars-width="50px"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <BarsChart
                ref="damageTypesDistributionChart"
                title="Findings"
                css="damage-types-distribution-chart"
                :data="damageTypesDistributionStats"
                bars-min-width="5px"
                :relative-toHighest-value="true"
                :includeCounts="false"
              />
            </div>
          </div>

          <div 
            v-if="isWindTurbine && hasLocationDamageFrequencyStats"  
            class="row"
          >
            <div class="col-sm-12">
              <BladeChart
                ref="bladeDamageLocationFrequencyChart"
                class="blade-damage-location-chart"
                :data="locationDamageFrequencyStats"
              />
            </div>
          </div>
        
        </div>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import DamageRating from "../shared/damage_rating";
import RowChart from "../shared/stats/row_chart";
import BarsChart from "../shared/stats/bars_chart";
import BladeChart from "../shared/stats/blade_chart";
import Timeline from "../shared/stats/timeline";

import helpers from "../../helpers.js";

export default {
  components: {
    DamageRating,
    RowChart,
    BarsChart,
    BladeChart,
    Timeline
  },

  props: {
    args: Object
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      isAccepted: false,
      siteId: null,
      site: {
        statistics: {},
        latestSummary: {},
        latestLeadingEdgeSummary: {}
      },
      ratingFrequencyStats: [],
      worstCaseDamageFrequencyStats: [],
      damageTypesDistributionStats: [],
      componentWorstCaseDamageCountsStats: [],
      componentWorstCaseDamageCountsTotal: 0,
      locationDamageFrequencyStats: {},
      timelineStats: []
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  computed: {
    lastActivityDate: function() {
      return this.site.latestSummary.approvedDate;
    },

    isWindTurbine: function() {
      return this.site.latestLeadingEdgeSummary.approvedDate
    },

    hasRatingFrequencyStats: function() {
      return this.ratingFrequencyStats.length > 1;
    },

    hasWorstCaseDamageFrequencyStats: function() {
      return this.worstCaseDamageFrequencyStats.length > 1;
    },

    hasComponentWorstCaseDamageCountsStats: function() {
      return this.componentWorstCaseDamageCountsStats.length > 1;
    },

    hasDefectTypeFrequencyStats: function() {
      return this.damageTypesDistributionStats.length > 1;
    },

    hasLocationDamageFrequencyStats: function() {
      return Object.keys(this.locationDamageFrequencyStats).length > 0;
    },

    hasStats: function() {
      return this.hasRatingFrequencyStats || this.hasWorstCaseDamageFrequencyStats || this.hasDefectTypeFrequencyStats || this.hasLocationDamageFrequencyStats || this.hasComponentWorstCaseDamageCountsStats;
    }
  },

  created: function() {
    if (this.args && this.args.siteId) {
      this.siteId = this.args.siteId;
    }
  },
  
  mounted: function() {
    if (this.siteId) {
      this.fetchSiteDetails();

      this.fetchSiteTimeline();
    }
  },

  methods: {
    damageLabel: function(label) {
      const lastBracket = label.lastIndexOf('(');

      return lastBracket > -1 ? label.substr(0, lastBracket).trim() : label;
    },

    hideModal: function() {
      window.vmModal.$refs.modal.hide()
    },

    setChartsData: function() {
      this.setRatingFrequencyBars();

      this.setWorstCaseDamageFrequencyBars();

      this.setComponentsWorstCaseDamageFrequencyBars();

      this.setDamageTypesDistributionBars();

      this.setDamageLocationFrequencyChart();
    },

    setRatingFrequencyBars() {
      let ratingFrequency = Object.entries(JSON.parse(this.site.statistics.ratingFrequency))

      let total = 0

      ratingFrequency.forEach((r) => {
        total += r[1]
      })

      let chartData = ratingFrequency.map((r) => {
        let percentage = r[1] / total * 100

        return [
          r[1],
          percentage,
          'Rating <strong>' + this.h$.damageMarks[r[0]] + ' - ' + r[1] + '</strong> (' + percentage.toFixed(2) + '%)',
          this.h$.damageColors[r[0]]
        ]
      })

      this.ratingFrequencyStats = chartData;
    },

    setWorstCaseDamageFrequencyBars() {
      let worstCaseDamageFrequency = Object.entries(JSON.parse(this.site.statistics.worstCaseDamageFrequency))

      let total = 0

      worstCaseDamageFrequency.forEach((r) => {
        total += r[1]
      })

      worstCaseDamageFrequency.forEach((r) => {
        let percentage = r[1] / total * 100

        r.push(this.h$.damageMarks[r[0]])
        r.push(percentage)
        r.push(total)
      });

      this.worstCaseDamageFrequencyStats = worstCaseDamageFrequency;
    },

    setComponentsWorstCaseDamageFrequencyBars() {
      let componentWorstCaseDamages = Object.entries(JSON.parse(this.site.statistics.componentWorstCaseDamageCounts))

      componentWorstCaseDamages.forEach((component) => {
        // fill the missing damages
        for (let i = 0; i < 6; i++) {
          if (!Object.keys(component[1]).includes(i.toString())) {
            component[1][i] = 0;
          }
        }

        let componentWorstCaseDamageFrequency = Object.entries(component[1]);

        let total = 0

        component[0] = this.damageLabel(component[0])

        componentWorstCaseDamageFrequency.forEach((r) => {
          total += r[1]
        })
        
        componentWorstCaseDamageFrequency.forEach((r) => {
          let percentage = r[1] / total * 100

          r.push(this.h$.damageMarks[r[0]])
          r.push(percentage)
          r.push(total)
        });

        this.componentWorstCaseDamageCountsTotal += total;

        component.push(componentWorstCaseDamageFrequency);
      });

      this.componentWorstCaseDamageCountsStats = componentWorstCaseDamages;
    },

    setDamageTypesDistributionBars() {
      this.damageTypesDistributionStats = Object.entries(JSON.parse(this.site.statistics.defectTypeFrequency));

      this.damageTypesDistributionStats.sort(function (a, b) { return b[1] - a[1]; });
    },

    setDamageLocationFrequencyChart() {
      let locationFrequency = Object.entries(JSON.parse(this.site.statistics.locationFrequency));
      
      locationFrequency.forEach((s) => {
        const key = s[0].toLowerCase().replace(/ /g,'')

        this.locationDamageFrequencyStats[key] = s[1]
      })
    },

    fetchSiteDetails: function() {
      app.graphql.get(
        "details.site",

        {
          filter: {
            id: this.siteId
          }
        },

        (data) => {
          this.isLoaded = true

          this.site = data.data.sites.edges[0].node
          
          this.setChartsData()

          this.isSpinnerVisible = false
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Site Details",
            message : "Something went wrong! Site cannot be found or doesn't exist!"
          })
        }
      );
    },

    fetchSiteTimeline: function() {
      app.graphql.get("grid.inspections",
        {
          orderBy: {
            field: "DATE",
            direction: "ASC"
          },
          filter: {
            siteId: this.siteId
          }
        },
        
        (data) => {
          this.setTimeline(data.data.inspections)
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch site timeline!"
          })
        }
      )
    },

    setTimeline: function(site) {
      let vmStats = this;

      let marks = []

      const firstDay = site.edges[0].node.date
      const lastDay = site.edges[[site.edges.length - 1]].node.date
      const totalDays = moment(lastDay).diff(moment(firstDay), 'days')

      site.edges.forEach(element => {
        const days = moment(element.node.date).diff(moment(firstDay), 'days')

        let mark = [
          vmStats.h$.formatDate.short(element.node.date),
          'Inspection',
          element.node.worstCaseRatings.clientRemediatedRating,
          days / totalDays * 100,
          element.node.project.id
        ]

        marks.push(mark)
      });

      this.timelineStats = marks
    },
  }
}
</script>