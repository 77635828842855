<template>
  <div 
    ref="map"
    v-bind:class="['map', {'spinner-is-visible': isSpinnerVisible}]"
  >
    <div class="spinner"></div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import Filter from "../shared/filter/findings_filter_core";

export default {
  extends: Filter,

  props: {
    googleMapApiKey: String,
    physicalAsset: Object
  },

  data: function() {
    return {
      filter: {},
      asset: null,
      markers: [],
      google: null,
      mapPin: null,
      mapOptions: {
        panControl: true,
        zoomControl: true,
        mapTypeControl: true,
        streetViewControl: false,
        gestureHandling: "cooperative",
        scaleControl: false,
        rotateControl: true,
        zoom: 18,
        minZoom: 2,
        maxZoom: 20,
        center: { 
          lat: this.physicalAsset.latitude,
          lng: this.physicalAsset.longitude
        },
        mapId: this.physicalAsset.id,
      },
      isSpinnerVisible: true,
      isInitialized: false,

      googleMapMaps: null,
      googleMapCore: null,
      googleMapMarker: null
    }
  },

  created: function () {
    let vmFilter = this;

    this.initStateFilter();

    // Listen for the event.
    window.addEventListener("updatestate", (event) => {
      let tmpFilter = vmFilter.getFilterState();

      if (tmpFilter) {
        tmpFilter = tmpFilter.replace("findingDetails", "details");

        tmpFilter = JSON.parse(tmpFilter);

        if (tmpFilter.details) {
          if (tmpFilter.details.constructor === Object && Object.keys(tmpFilter.details).length >= 0) {
            tmpFilter.details = JSON.stringify(tmpFilter.details);
          }
        }

        vmFilter.filter = Object.assign({}, tmpFilter);
      }
    });

    // Handle browser history change
    window.addEventListener("popstate", (event) => {
      const shouldRefresh = (event.state || event.isTrusted) && event.state === null;

      if (shouldRefresh) {
        this.refresh();
      }
    });
  },

  async mounted() {
    const loader = new Loader({
      apiKey: this.googleMapApiKey
    });

    this.googleMapCore = await loader.importLibrary("core");
    this.googleMapMarker = await loader.importLibrary("marker");
    this.googleMapMaps = await loader.importLibrary("maps");

    this.google = this.googleMapMaps;
    
    this.initializeMap();
  },

  watch: {
    filter: function() {
      if (this.google) {
        this.refresh();
      }
    },

    google: function() {
      this.refresh();
    }
  },

  computed: {
    filterQueryParams: function() {
      return (!this.isFilterEmpty) ? "?v-filter=" + JSON.stringify(this.filter) : "";
    },

    isFilterEmpty: function() {
      return (Object.keys(this.filter).filter(x => !["physicalAssetId", "siteId", "historical", "status"].includes(x))).length === 0;
    },

    isFilterInHistoricalMode: function() {
      return this.filter && this.filter.historical && this.filter.historical === true;
    },

    isFilterInPrevievMode: function() {
      return this.filter.status ? ["approved", "draft", "review"].every((val) => this.filter.status.includes(val)) : false;
    },

    isProjectFilter: function () {
      return (this.filter.projectId != undefined && this.filter.projectId !== [] && this.filter.projectId.length == 1);
    },
    
    filterParams: function () {
      delete this.filter.siteId;
      delete this.filter.preview;

      let params = {
        id: this.physicalAsset.id,
      };
      
      if (!this.isFilterEmpty || this.isFilterInHistoricalMode || this.isFilterInPrevievMode) {
        params.findings = this.filter;

        params.findings.physicalAssetId = this.physicalAsset.id;
        params.findings.historical = this.filter.historical || false;
      };

      return params;
    },

    queryName: function () {
      if (this.isProjectFilter) {
        return "grid.site.projectAssets";
      } else {
        return "grid.site.assets";
      }
    },

    damageRating: function() {
      let rating = 0;

      if (this.asset) {
        rating = this.isFilterInPrevievMode ? this.asset.latestSummary.remediationPreviewRating : this.asset.latestSummary.clientRemediatedRating;
      }

      return rating;
    }
  },

  methods: {
    refresh: function() {
      this.fetchAsset();
    },

    initializeMap: function() {
      this.mapOptions.mapTypeControlOptions = {
        style: this.googleMapMaps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: this.googleMapCore.ControlPosition.TOP_RIGHT
      };

      this.mapOptions.mapTypeId = this.googleMapMaps.MapTypeId.HYBRID;

      this.mapOptions.zoomControlOptions = {
        position: this.googleMapCore.ControlPosition.RIGHT_BOTTOM
      };

      // Draw map
      const mapContainer = this.$refs.map;

      this.map = new this.googleMapMaps.Map(mapContainer, this.mapOptions);

      // Hide spinner on map load
      this.map.addListener("tilesloaded", () => {
        this.isSpinnerVisible = false;
      });
    },

    addAssetToMap: function() {
      const vmMap = this;

      if (!this.mapPin) {
        // Add Asset mark
        const mark = document.createElement("div");

        mark.className = "map-info-marker rating-" + this.damageRating;
        mark.innerHTML = "<div>" + this.physicalAsset.name + "</div>" + (this.physicalAsset.description ? "<div class='note'>" + this.physicalAsset.description + "</div>" : "");

        const marker = new this.googleMapMarker.AdvancedMarkerElement({
          map: vmMap.map,
          position: { lat: vmMap.physicalAsset.latitude, lng: vmMap.physicalAsset.longitude },
          content: mark,
        });

        marker.addListener("click", (e) => {
          vmDetailsPane.$refs.detailsPane.open({
            title: "Physical Asset Details",
            entity: vmMap.physicalAsset.id,
            entityType: "physicalAssetDetails",
            trackHistory: false,
            args: null
          });
        });
      }
      else {
        this.mapPin.content.className = "map-info-marker rating-" + this.damageRating;
      }
    },

    queryParams: function (params) {
      if (this.isProjectFilter) {
        params.projectId = this.filter.projectId[0];
      }

      return Object.assign({
        preview: this.isFilterInPrevievMode,
        includeLECondition: false
      }, params);
    },

    fetchAsset: function() {
      let fetchParams = {
        filter: this.filterParams
      };

      app.graphql.get(this.queryName,
        this.queryParams(fetchParams),

        (data) => {
          this.asset = data.data.physicalAssets.edges[0] ? data.data.physicalAssets.edges[0].node : null;

          if (this.asset) {
            this.addAssetToMap();
          }
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch Physical Asset!"
          });
        }
      )
    }
  }
}
</script>
