<template>
  <div 
    ref="map"
    v-bind:class="['map', {'spinner-is-visible': isSpinnerVisible}]"
  >
    <div class="spinner"></div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

import Helpers from "../../../helpers.js";

export default {
  props: {
    googleMapApiKey: String,
    physicalAsset: Object,
    assets: Array
  },

  data: function() {
    return {
      markers: [],
      google: null,
      mapPins: [],
      mapBounds: null,
      mapOptions: {
        mapTypeId: 'roadmap', //hybrid
        panControl: true,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        gestureHandling: 'cooperative',
        scaleControl: false,
        rotateControl: false,
        zoom: 4,
        minZoom: 2,
        maxZoom: 20,
        center: { 
          lat: 39.5, 
          lng: -98.35 
        },
        mapId: 'projectAssetsMap',
      },
      isSpinnerVisible: true,
      isInitialized: false,

      googleMapMaps: null,
      googleMapCore: null,
      googleMapMarker: null
    }
  },

  setup () {
    return {
      h$: Helpers
    }
  },

  created: function () {
    
  },

  async mounted() {
    const loader = new Loader({
      apiKey: this.googleMapApiKey
    });

    this.googleMapCore = await loader.importLibrary("core");
    this.googleMapMarker = await loader.importLibrary("marker");
    this.googleMapMaps = await loader.importLibrary("maps");

    this.google = this.googleMapMaps;
    
    this.initializeMap();
  },

  watch: {
    assets: function() {
      if (this.google) {
        this.refresh();
      }
    },

    google: function() {
      this.refresh();
    }
  },

  computed: {
    
  },

  methods: {
    refresh: function() {
      this.addAssetsOnMap()
    },

    initializeMap: function() {
      this.mapOptions.mapTypeControlOptions = {
        style: this.googleMapMaps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: this.googleMapCore.ControlPosition.TOP_RIGHT
      };

      this.mapOptions.zoomControlOptions = {
        position: this.googleMapCore.ControlPosition.RIGHT_BOTTOM
      };

      // Draw map
      const mapContainer = this.$refs.map;

      this.map = new this.googleMapMaps.Map(mapContainer, this.mapOptions);

      // Hide spinner on map load
      this.map.addListener("tilesloaded", () => {
        this.isSpinnerVisible = false;
      });
    },

    clearMap: function() {
      if (this.isInitialized) {
        this.mapBounds = new this.googleMapCore.LatLngBounds();

        this.mapPins.forEach((pin) => {          
          pin.setMap(null);
        });

        this.mapPins = [];
      }
    },

    addAssetsOnMap: function() {
      const vmMap = this;

      // remove all map pins
      this.clearMap();
      
      // Add markers on map
      if (this.assets.length > 0) {
        this.assets.forEach((asset, i) => {

          let icon = {
            url: 'data:image/png;base64,' + this.h$.mapPinRatingMarks[asset.rating],
            scaledSize: new this.googleMapCore.Size(16,16),
            origin: new this.googleMapCore.Point(0,0),
            anchor: new this.googleMapCore.Point(0,0)
          };

          const marker = new this.googleMapMarker.Marker({
            position: { lat: asset.latitude, lng: asset.longitude },
            map: vmMap.map,
            icon: icon,
            title: asset.inspectionType
          });

          this.mapPins.push(marker);

          this.mapBounds.extend(new this.googleMapCore.LatLng(asset.latitude, asset.longitude));
        })
      
        this.map.fitBounds(this.mapBounds);
      }

      if (this.mapBounds) {
        this.map.setCenter(this.mapBounds.getCenter());
      }

      this.isInitialized = true;
    },
  }
}
</script>
