<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
    <div v-bind:class="['entity-details', {'hidden': !isLoaded }]">
      <section>
        <header>
          <div>{{ fulcrumApp.name }}</div>
        </header>
        <div 
          v-if="fulcrumApp.dashboardGroups"
          class="entity-detail form-group" 
        >
          <label class="placeholder placeholder-static">Dashboard Groups</label>
          <div v-for="(group) in fulcrumApp.dashboardGroups" :key="group">{{ group }}</div>
        </div>
      </section>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import helpers from "../../helpers.js";

import EntityDetails from "../shared/details/entity_details";

export default {
  extends: EntityDetails,

  components: {

  },

  setup () {
    return {
      h$: helpers
    }
  },

  data() {
    return {
      fulcrumApp: {
        fields: [],
        statusField: {}
      }
    }
  },

  watch: {
    entityId: function() {
      this.fetchDetails()
    }
  },

  computed: {
    
  },

  mounted: function() {
    this.fetchDetails()
  },

  methods: {
    fetchDetails: function() {
      this.isSpinnerVisible = true;

      app.graphql.get(
        "details.fulcrumApp",

        {
          filter: {
            formId: this.entityId
          }
        },

        (data) => {
          this.isLoaded = true

          this.fulcrumApp = data.data.fulcrumApps.edges[0].node;

          this.fulcrumApp.elements = JSON.parse(this.fulcrumApp.elements) || {};
          this.fulcrumApp.statusField = JSON.parse(this.fulcrumApp.statusField) || {};
          this.fulcrumApp.fields = this.fulcrumApp.fields.map((field) => {return JSON.parse(field) });

          // console.log(this.fulcrumApp)

          setTimeout(() => {
            this.isSpinnerVisible = false
          }, 500)
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Fulcrum App Details",
            message : "Something went wrong! Fulcrum App cannot be found or doesn't exist!"
          })
        }
      );
    }
  }
}

</script>
