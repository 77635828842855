<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label class="placeholder placeholder-inactive">Client Company</label>
        <v-select
          placeholder="Select client company"
          ref="clientCompanies"
          :class="[{'vs-error': v$.clientCompany.$error}]"
          :options="clientCompanies"
          label="name"
          v-model="clientCompany"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <label class="placeholder placeholder-inactive">Project Year</label>
        <v-select
          placeholder="Select year"
          ref="projectYear"
          :class="[{'vs-error': v$.projectPrefix.$error}]"
          :options="projectYears"
          @update:modelValue="setProjectYear"
        />
      </div>
    </div>
    <div class="col-sm-3">
      <div class="form-group">
        <label class="placeholder placeholder-inactive" for="current_component_status_project_prefix">Project Prefix</label>
        <input
          :class="['form-control', {'error': v$.projectPrefix.$error}]"
          type="text"
          name="project_prefix"
          id="current_component_status_project_prefix"
          readonly
          v-model.trim="projectPrefix"
        />
      </div>
    </div>
    <div v-if="v$.clientCompany.$error && v$.projectPrefix.$error" class="col-sm-12" style="margin-top: -7px;"><div class="error">Either Client info or Project prefix is required</div></div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators'

import vSelect from 'vue-select';

const conditionalyRequired = (value, vm) => helpers.req(vm.projectPrefix) || helpers.req(vm.clientCompany)

export default {
  components: {
    vSelect
  },

  props: {
    parameters: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      clientCompanies: this.clientCompanies,
      clientCompany: null,
      projectPrefix: '',
      projectYear: '',
      projectYears: []
    }
  },

  watch: {
    clientCompany: function() {
      this.updateParameters('client_company_id', this.clientCompany ? this.clientCompany.id : '');
    },
    projectPrefix: function() {      
      this.updateParameters('project_prefix', this.projectPrefix);
    },
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  validations () {
    return {
      projectPrefix: {
        conditionalyRequired
      },
      clientCompany: {
        conditionalyRequired
      }
    }
  },

  mounted: function() {
    this.setProjectYears();

    this.fetchClientCompanies();
  },

  methods: {
    updateParameters: function(param, value) {
      this.$emit('update:parameters', { ...this.parameters, [param]: value });
    },

    setProjectYears: function() {
      const currentYear = new Date().getFullYear();
      const years = [];
      
      for (let year = 2023; year <= currentYear; year++) {
        years.push(year);
      }

      this.projectYears = years.reverse();
    },

    setProjectYear: function(opt) {
      this.projectYear = (opt) ? opt : '';

      this.setProjectPrefix(this.projectYear);
    },

    setProjectPrefix: function(year) {
      let prefix = '';
      
      year = year.toString();
      
      if (year != '' & year.length > 2) {
        prefix = year.slice(2) + '-1'
      }
      
      this.projectPrefix = prefix;
    },

    fetchClientCompanies: function() {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.clientCompanies = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.clientCompanies",
          fetchParams,

          (data) => {
            let clientCompanies = data.data.clientCompanies,
                pageInfo = clientCompanies.pageInfo;

            success(clientCompanies, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Client Companies!"
            });
          }
        )
      };

      let getClientCompanies = (cursor) => {
        fetch(
          cursor,

          (clientCompanies, pageInfo) => {
            clientCompanies.edges.forEach((edge) => this.clientCompanies.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getClientCompanies(pageInfo.endCursor))
            }
          }
        )
      };

      getClientCompanies(window.btoa(0));
    },
  }
}
</script>
