<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
    <div class="progress entity-details-progress">
      <div class="progress-bar progress-bar-success" :style="{ width: progress + '%' }"></div>
    </div>
    <div v-bind:class="['entity-details', {'hidden': !isLoaded }]">
      <section>
        <div class="photo-gallery">
          <photos
            :photos="photos"
            :gallery="entityId"
            :user-can-update="userCanUpdate"
            entity-type="remediation"
            @update-photo="fetchRemediationPhotos"
            @progress="uploadProgress"
          ></photos>
        </div>
      </section>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import Vue from "vue";
import EntityDetails from "./entity_details";
import Photos from "./photos";

export default {
  extends: EntityDetails,

  components: {
    Photos
  },

  props: {
    entityId: String,
    userCanUpdate: Boolean
  },

  data() {
    return {
      remediation: {},
      progress: 0,
      photos: [],
      isLoaded: false,
      isSpinnerVisible: true
    }
  },

  watch: {
    entityId: function() {
      this.fetchRemediationPhotos();
    }
  },

  mounted: function() {
    this.fetchRemediationPhotos();
  },

  methods: {
    uploadProgress: function(progress) {
      this.progress = progress;
    },

    fetchRemediationPhotos: function() {
      this.isSpinnerVisible = true;

      app.graphql.get(
        "details.remediationPhotos",

        {
          filter: {
            remediationId: this.entityId
          }
        },

        (data) => {
          this.isLoaded = true;

          this.photos = data.data.remediationPhotos.edges.map((photo) => {return photo.node});

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 500);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Remediation photos",
            message : "Something went wrong! Remediation photos cannot be found!"
          });
        }
      );
    }
  }
}

</script>