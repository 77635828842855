<template>
  <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <label class="placeholder placeholder-inactive">Project Year</label>
        <v-select
          placeholder="Select year"
          ref="projectYear"
          :class="[{'vs-error': v$.projectPrefix.$error}]"
          :options="projectYears"
          @update:modelValue="setProjectYear"
        />
      </div>
    </div>
    <div class="col-sm-3">
      <div class="form-group">
        <label class="placeholder placeholder-inactive" for="inspected_assets_project_prefix">Project Prefix</label>
        <input
          :class="['form-control', {'error': v$.projectPrefix.$error}]"
          type="text"
          name="project_prefix"
          id="inspected_assets_project_prefix"
          readonly
          v-model.trim="projectPrefix"
        />
      </div>
    </div>
    <div v-if="v$.projectPrefix.$error" class="col-sm-12" style="margin-top: -7px;"><div class="error">Project prefix is required</div></div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import vSelect from 'vue-select';

export default {
  components: {
    vSelect
  },

  props: {
    parameters: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      projectPrefix: '',
      projectYear: '',
      projectYears: []
    }
  },

  watch: {
    projectPrefix: function() {      
      this.updateParameters('project_prefix', this.projectPrefix);
    },
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  validations () {
    return {
      projectPrefix: {
        required
      }
    }
  },

  mounted: function() {
    this.setProjectYears();
  },

  methods: {
    updateParameters: function(param, value) {
      this.$emit('update:parameters', { ...this.parameters, [param]: value });
    },

    setProjectYears: function() {
      const currentYear = new Date().getFullYear();
      const years = [];
      
      for (let year = 2023; year <= currentYear; year++) {
        years.push(year);
      }

      this.projectYears = years.reverse();
    },

    setProjectYear: function(opt) {
      this.projectYear = (opt) ? opt : '';

      this.setProjectPrefix(this.projectYear);
    },

    setProjectPrefix: function(year) {
      let prefix = '';
      
      year = year.toString();
      
      if (year != '' & year.length > 2) {
        prefix = year.slice(2) + '-1'
      }
      
      this.projectPrefix = prefix;
    }
  }
}
</script>
