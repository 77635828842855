<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 200px;">
    <div v-bind:class="{'hidden': !isLoaded }">

      <div class="row" @keydown.enter.prevent>
        <div class="col-sm-6 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-static">Inspected by</label>
            <v-select
              multiple
              placeholder="Select inspectors"
              ref="inspectors"
              :class="[{'vs-error': v$.selectedInspectors.$error}]"
              :options="inspectors"
              label="name"
              @option:selected="selectInspectors"
              @option:deselected="deselectInspectors"
            />
            <div v-if="v$.selectedInspectors.$error" class="error">At least one inspector should be defined</div>
            <input
              v-for="(inspector, index) in selectedInspectors"
              v-bind="inspector"
              :key="inspector.id"
              :index="index"
              type="hidden"
              name="inspected_by[]"
              :value="inspector.id"
            />
          </div>
        </div>
      </div>
      
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <div class="form-group">
            <label for="file" class="placeholder placeholder-inactive">Findings</label>
            <form-field-file
              id="file"
              name="file"
              placeholder="Select findings import file"
              :class="[{'file-error': v$.choosenFile.$error}]"
              accept=".csv"
              @change-file="setFile"
            ></form-field-file>
            <div v-if="v$.choosenFile.$error" class="error">Findings file is required</div>
          </div>
        </div>
      </div>
            
      <div class="form-group">
        <form-button
          ref="submitButton"
          label="Import Findings"
          class="d-none d-lg-block"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import formFieldFile from "../shared/form/field_file";
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  components: {
    vSelect,
    formFieldFile,
    formButton
  },

  props: {
    formId: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      inspectors: [],
      selectedInspectors: [],
      choosenFile: ""
    }
  },

  validations () {
    return {
      selectedInspectors: {
        required
      },
      choosenFile: {
        required
      }
    }
  },

  mounted: function() {
    this.fetchInspectors();
  },

  methods: {
    fetchInspectors: function() {
      let fetchParams = {
        pageSize: 100,
        filter: {
          active: true
        },
        orderBy: {
          field: "NAME"
        }
      };

      this.inspectors = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.inspectors",
          fetchParams,

          (data) => {
            let inspectors = data.data.inspectors,
                pageInfo = inspectors.pageInfo;

            success(inspectors, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch inspectors!"
            });
          }
        )
      };

      let getInspectors = (cursor) => {
        fetch(
          cursor,

          (inspectors, pageInfo) => {
            inspectors.edges.forEach((edge) => this.inspectors.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getInspectors(pageInfo.endCursor));
            }

            this.isLoaded = true;
          
            setTimeout(() => {
              this.isSpinnerVisible = false;
            }, 1000);
          }
        )
      };

      getInspectors(window.btoa(0));
    },

    selectInspectors: function(opt) {
      this.v$.$reset();

      this.selectedInspectors = opt ? opt : [];
    },

    deselectInspectors: function(opt) {
      this.selectedInspectors = this.selectedInspectors.filter(inspector => inspector !== opt);
    },

    setFile: function(file) {
      this.choosenFile = (file && file.name) ? file.name : "";
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>