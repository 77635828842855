<template>
  <div @keydown.enter.prevent>
    <fieldset>
      <legend>Physical Asset type</legend>
      <div class="row">
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive">Physical Asset Type</label>
            <v-select
              placeholder="Select physical asset type"
              ref="physicalAssetTypes"
              :class="[{'vs-error': v$.physicalAssetTypeId.$error}]"
              :options="physicalAssetTypes"
              label="name"
              @update:modelValue="setPhysicalAssetType"
            />
            <div v-if="v$.physicalAssetTypeId.$error" class="error">PhysicalAsset type is required</div>
            <input
              type="hidden"
              id="physical_asset_physical_asset_type_id"
              name="physical_asset[physical_asset_type_id]"
              :value="physicalAssetTypeId"
            />
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset v-show="physicalAssetTypeId">
      <legend>Physical Asset details</legend>
      <template 
        v-for="(item, index) in physicalAssetTypeDetails"
        v-bind="item"
        :key="item.name"
        :index="index"
      >
        <component
          v-bind:is="item.formControl.type"
          :placeholder="item.name"
          :name="item.formControl.name"
          :id="item.formControl.id"
          :value = "item.defaultValue"
          :options="item.enum"
          :shouldBeValidated="item.shouldBeValidated"
          ref="physicalAssetDetail"
        ></component>
      </template>
    </fieldset>
  </div>
</template>

<script>
import vSelect from "vue-select";

import formSelect from "../shared/form/field_select";
import formMultiSelect from "../shared/form/field_multiselect";
import formText from "../shared/form/field_text";
import formBoolean from "../shared/form/field_boolean";
import formDate from "../shared/form/field_date";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
    formSelect,
    formMultiSelect,
    formText,
    formBoolean,
    formDate
  },

  props: {
    physicalAsset: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data: function () {
    return {
      physicalAssetTypes: [],
      physicalAssetTypeId: null,
      physicalAssetTypeDetails: [],
      physicalAssetTypesAreValid: true
    }
  },

  validations () {
    return {
      physicalAssetTypeId: {
        required
      }
    }
  },

  computed: {
    isValid: function() {
      this.validate();

      return !this.v$.$error && this.physicalAssetTypesAreValid;
    }
  },

  mounted: function () {
    this.fetchPhysicalAssetTypes();
  },

  methods: {
    setPhysicalAssetType: function(opt) {
      this.physicalAssetTypeDetails = [];
      this.physicalAssetTypeId = null;

      if (opt) {
        this.physicalAssetTypeId = opt.id;

        if (opt.details) {
          this.setPhysicalAssetTypeDetails(opt.details);
        }
      }

      this.$emit("change-physical-asset-type", opt);
    },

    fetchPhysicalAssetTypes: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.physicalAssetTypes = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.assetTypes",
          fetchParams,

          (data) => {
            let physicalAssetTypes = data.data.physicalAssetTypes,
                pageInfo = physicalAssetTypes.pageInfo;

            success(physicalAssetTypes, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Asset Types!"
            });
          }
        )
      };

      let getPhysicalAssetTypes = (cursor) => {
        fetch(
          cursor,

          (physicalAssetTypes, pageInfo) => {
            physicalAssetTypes.edges.forEach((edge) => {
              if (edge.node.details) {
                edge.node.details = JSON.parse(edge.node.details)
              }
              
              this.physicalAssetTypes.push(edge.node)
            })

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getPhysicalAssetTypes(pageInfo.endCursor))
            }
            else {
              if (this.physicalAsset) {
                const currentAssetType = this.physicalAsset.physicalAssetType;
                const currentAssetTypes = this.physicalAssetTypes.filter(assetType => assetType.id == currentAssetType.id);

                if (currentAssetTypes.length > 0) {
                  this.$refs.physicalAssetTypes.updateValue(currentAssetTypes[0]);
                }
              }
            }
          }
        )
      };

      getPhysicalAssetTypes(window.btoa(0));
    },

    setPhysicalAssetTypeDetails: function(details) {
      details.forEach((physicalAssetTypeDetail) => {
        if (this.physicalAsset && this.physicalAsset.physicalAssetType.id == this.physicalAssetTypeId) {
          if (Object.keys(this.physicalAsset.details).includes(physicalAssetTypeDetail.name)) {
            physicalAssetTypeDetail.defaultValue = this.physicalAsset.details[physicalAssetTypeDetail.name];
          }
        }

        physicalAssetTypeDetail.formControl = {}

        physicalAssetTypeDetail.formControl.type = 'formText'
        physicalAssetTypeDetail.formControl.name = "physical_asset[details][" + physicalAssetTypeDetail.name + "]";
        physicalAssetTypeDetail.formControl.id = "physical_asset_details_" + physicalAssetTypeDetail.name;

        switch(physicalAssetTypeDetail.type) {
          case "string":
            if (physicalAssetTypeDetail.format && physicalAssetTypeDetail.format == "date") {
              physicalAssetTypeDetail.formControl.type = 'formDate'
            }
            else if (physicalAssetTypeDetail.enum) {
              if (physicalAssetTypeDetail.multiple) {
                physicalAssetTypeDetail.formControl.type = 'formMultiSelect'
                physicalAssetTypeDetail.formControl.name += "[]";
                physicalAssetTypeDetail.formControl.id += "[]";
              }
              else {
                physicalAssetTypeDetail.formControl.type = 'formSelect'
              }
            }
            break;
          case "integer":
            physicalAssetTypeDetail.formControl.type = 'formText'
            break;
          case "number":
            physicalAssetTypeDetail.formControl.type = 'formText'
            break;
          case "boolean":
            physicalAssetTypeDetail.formControl.type = 'formBoolean'
            break;
        }

        // field should be validated if (new asset) or (existing asset && detail has value))
        physicalAssetTypeDetail.shouldBeValidated = (!this.physicalAsset) || (this.physicalAsset.id !== undefined && physicalAssetTypeDetail.defaultValue !== undefined)

        this.physicalAssetTypeDetails.push(physicalAssetTypeDetail);
      });
    },

    validate: function() {
      this.v$.physicalAssetTypeId.$touch();
      
      this.physicalAssetTypesAreValid = true;

      if (this.physicalAssetTypeDetails.length > 0) {
        this.$refs.physicalAssetDetail.forEach(detail => {
          if (detail.v$) {
            detail.v$.$touch();

            if (detail.v$.$error) this.physicalAssetTypesAreValid = false;
          }
        })
      }

      this.$emit("validate-form");
    }
  }
}
</script>
