import moment from "moment";

export default {
  damageMarks: ["-", "A", "B", "C", "D", "F"],
  damageColors: ["#c0c0c0", "#5cb85c", "#5bc0de", "#f4ba1c", "#ff8800", "#d9534f"],
  chartColors: ["#e24b2e", "#f4b337", "#8fb737", "#1c91a8", "#1d2b82", "#9e0678", "#ea7e32", "#2b954f", "#4f2473", "#c4c4c4", "#9b9b9b", "#015e9c", "#770059", "#c24128", "#d86811", "#639963"],

  mapPinRatingMarks: [
    'iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALdSURBVHgBxVdLbhpBEK0ZW7axjBg+AoGQICtWKM4uO+IdS1hmldwgZsMW5wTADcwJ4i0rJyeIIw7AIBB/yPAX/9QbzSCCsIwwjJ+Ehulp9XtV1V1dJdCOSCaTEj8ii8UiJAjC9XK59PO7pH1WeEzmsSf+/0sUxYdYLKbssq7w0gQm9jPpt9PT068XFxeS1Wqlq6srOj8/p5OTE3XOfD6n4XBIo9GIut0udTodDN+zkO8sRN5LgGZxgi27dTqd5HK5VoQvYTKZULPZpFarhf8pTYiyswDN6kcm9ns8np2Jtwkpl8sQIrOIm23eELaQXzP5D6/X64fVh0C9XqdisShzqKLxePzpWQG65T6fz+9wOOiQQDhkxqYnhDVyicl/H9LyTeieYBEf9D0hrn1P2O32o5ED2MzYV2xoQh9TPQDX8zHLBwIBOjs7o2MCRzaXyxHnjHcIheoBKGLrj04O4ETBEyzgFu+CFvu/wWDQEAGA5gUFXhCZPCJJkmHkALxweXkJwyMIQQip1WhYLBY8QiIuFlZDRsNsNhO4RdxqbyEAlxm4EQJp31z/GmickkhvDAhQcCyMhsapYBPK4/GYjAYKGHDDA396vR4ZDQhACYdT8FMroQxFv9/HA/Wj+MBqDN0HCLmiKARuEfcyp8RMrVYjo9But/G4B7d6DHkzpFAsGOEFWI+CFYUq3lUBuJdns1kaBeSxUalUICKtl2WrRMSZ6a7RaMjwxLGAtdn9Mrj0sZUAxINDcVMqlWQUkIcG1sTa4FjvEf5LxVooooVC4aCewFpYk8mjm73Bs40J54dHm83md7vd6s21D9gYqlarEKBbLm/O2XoNZrNZJRwOZwaDgYmT1Ec+pmrFxIUr7UqMY53P59ErpnnHf2by6ra5OzWnfDzv2IIvKN1QSJhMJvWnCwIh2jBkNyQYblKV6XSa4c2W2rs53SJEreE4NJ/Yovc8hDCp7TmLw6baqz3/B8w0qEGAZ+1NAAAAAElFTkSuQmCC',
    'iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM5SURBVHgBvVdLTxpRFD5zp+DwCA74KBFNR7voUjampAsfSRdd0mVX7T+oy6YbMWlSl/oP6q5Ld10qqQvLiq76SE2nKRIUgUFBwOnM9H5TIPiKhMJ8CeHO5XK+87j3zncE6hKLiUW5el6NM2ILpmVGLcFSBEuQ8Rsfa3yskkBp/pj0uX1bO4kdrRu7wm0LYq9iiiEYL9kd9kLySbJ/1E8e2UNuyU18zl5j/jGpXqlTo9KgM+2MKoUKLL8TTXF1b21P7ckBO+JGdYWTLAcjQQpNhtqEt0Gv61TOlenk8ATjdX1IX00n0lrXDjSj3ubEyqgy2jXxdY4U1AKVD8uqaIlL12VDvDwx93ouapH1Yfz+uE0usFurdCPEOyKhZPxbrpQq8ch8JJn9mM11rrlgvRV5+EFYGQ4PUz+BkuS+5a5kop1b1BzkiLzf5ABswjY4oomofMUBbDi+SAlOBmlQgG3sK1fDtdKas0uA1DMP+zk1O0UuyUWDBI7s/qd9EnRhGqWwM2AwYyVwNzBwcgAnKhQJkUHGMp4F1IOnpDTzcMYRB4BmFrSG2JhmrnNX3D/id4wcQBYkvySDGyVY8MpechoIGu8VOBAd8g+R00DQeKnBAYWng5xGs+QKI4vkXu/6/0GTU3ae+RIYv4o0HAun0eTUkAEVr02nAfECbsal1GeoGKcBBQUJx7ie27EllMOoaTVeB0oy3a1vcW8c3Qco+WnhlHRJ32LQaqZubpYyJXIK0IoQreC2j6FI4nrxoEhOZKElWKGY/3FzZHYzWvhROMgvpZgv5KNBIv8jD+m+kVpLvcdz+yIyJCOhZTR1kKUoHZSQftUn+RKtubYDdj0stpTfz6t2jfoM2OTRq+Do7JouyHKUYmJ+Ilk9rj7hd7XsCXioH0DkR9+PVH7kn6bepr52/nZjY2IK5nYgHFBG7o30LFawqY9/HRNKi8i7akwAZGLs8dhmQ2t4qoVqzDRM2wk0Gt0SF38XKfslS7VSbYPX/Nnum93cdWu7bU4TfPgcXY532As5RRAxnc0pjlerMbUvNn634Hj33Jxeht2s1qtx/o9F/pnlUwq0RNMKNhWX2CxtmmaSR9x1e/4XsD6Zcw67t84AAAAASUVORK5CYII=',
    'iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALYSURBVHgBxVdtbhJRFL3vTYGCWEYiET8zppqY/pGuoLoDWYHuoGUFtiso3UG7AtyBuoLWP6aJaRxrqzU1MBBqy3SG5z0DQ5CCRWReTzIkvJm8c7/efecKGhPWesU0iF6QEEuKqCB4iZfN7muH12xe2yGl3vtEb+xS0RlnX3HZB0xszQi5bBjyVSo5a2YzGZpLpykej9GMYQTfeL5Pv07P+DmlRrNJ1XoDy5sxpdZ2S0V7IgPgsRTydcwQK/lcjvK5mz3Cy9ByXTqu1vip0pnrlduqvTYqImKU14YQb5nUupe/NTbxMEMOjn7AGJuj8XxYNC4Y8Hi9UmgLUbHu3rHg9TTw/fgnfTn8Zkulip9KxZ2RBoSezz+4b+WyN2iaQDr29g8uREL0kZtMvj1NzwcRRsJXajGsCRm+RMGx15GRA7d5b34scIVrQQQQ+lQi/nnh0Twl4nGKEj4f2e2PuyQ87yFSEUSAQ8/eZyMnD7j4RCHK50Qr+C+6ua8tLjzRYgDQjYLT4ihItNdsZk4bOYAooKvGmFuit6O16gZaOvebJclVWEglk6Qb19PXCJea5B+Lw0G6Mcspx42KU2BO2uv/B0aH05R0xYABDu5z3fA7nA6K0Hb52tSNExYwUFF8Q6oPjeYJ6QbUEyQcUvCuWq+TbtRZukE/SghIDofWOoBSqrFuBLfEvex73tYR39W6AL3I2AR3cAzZkjIM0BGFULBCMeN/YABbYnuet3HIAjJqQKS2Wq2NUJb1GhH7vsqSyY4yFdgbChlc4VrPgKAWWDDarNkgIKcNhB17Q5T2zwh/tGKkAtIZ6nWakcBee/tfg70HZ4O/DiYQqRhMJhUrKGrUFVI79mDSZwSG0dVEIrGMGQHPuIaAGF4HJ4uLGzn/p9FswBALhrByegnpBvUEAQMN0T+cuu55dzCtY1B1mHiLJVd54uF0iCGd8ZzomRDiqRoYz3GpqQnG899/tIdlQip3SAAAAABJRU5ErkJggg==',
    'iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANQSURBVHgBvVdLTxNRFD73Th9TaO2EQIIEw5BAIhuscaMLRRMTXRlYEhc6v0CWxg0lMeoSfgGwMC7Bna6UxITExKSyEB9NmEYCTajpIC2daWfmes5gSWPb0NR2vtW0c+d+53HPud9h0CLWkopyVDan8XGKCZYQzFVBMMV7yYTBBNcZEykXYCMWktdnkobRyr7srAWvHsuqw9ijQIA9jEcl5fxAGPqVIPTKHIKBk88rtgCjYMNhwYFcvgJ7OQs4gxXJhYXZF6belgGex5Y1jyRzYxdkGBuOnBKehWPThUzWhMy+BaZpL/aGIwvNIsKaeV1h8A5J1YnRnpaJGxmyvXNMxuhBAbcaRaNu55dPgglHSGuT470qed0JpHdN2PpR0CUmzdx/Vkw1NaDq+ZWJmDoyGIZOIpO14NP2UV0kePWBck7k5HmnyQm05+R41HOQuOoMoAM3Mih3LOyNMDbsHWa1aJXmq/95KaDQByPSzvXLca+8ugkq2TebeXxwRykVHpvDYZ5C1G1yAlUUlbUNbI5+s2XMh2SZ+TvX+nwxgEBReLuZN8JSaJRL2F6H+kO+kRMoCuewq1Jr50g7Ra3VbwwNeJU2xQWwRDwaAL8xgE4zIRLcBVdVYv4b0IMpF8BVjler0m6v/x+ccArFv5PXBFygmKCy8BsnnMzAKuD6semA3yABw8DVuSvE5wNUMX6D1JNgLMUZg/d7uTL4Dc9pFza4gwLyd9Hx9RyQUtpH3RiT5XWuoVazymI1/bMEfoH0Ih6AFdKJXhm6IBbTuyXwIwqeYEWx6qBipt+eARrey+UKLJGA7DaIo1Ryl7S/suy0EblyOIlR0LuZChKnpJBt5Kr+d2oAnQUHBeNWuqiTgOw0aE9SxsSh1cwIUu2i1x9s494NtpE9qNzFXq30xTtzTZPnqW9IzpwZ7Xn5a+27hrfQMmpECdUridSLOJi0K1boUFPOKbWe5w0GE6nRh14kbkdXDw0rsp8rX6WNeiIShFq8NWn990wJPn4pwK+8veTI8qz2tJBttPbMHSkaOGgmceEDkm79SghwSAXSELXDabGEg6lhew0G26xhVcQqlbfW7nBaZwiJV9OcFgxuYv++JOCf8RwvNQEiRe0VPV7XWhzP/wCv3KUhz38O/AAAAABJRU5ErkJggg==',
    'iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAL9SURBVHgBxVddUtpQGD0JEP5GjUXxp9NOeKhvTnnou80OdAVlB7oDcQe6A1xB3QF1AZ2xfbUzZuooAqJxLCoQSL8DwX8qZSCemcAkXL7v3HO/e3M+BX0inzF04GZZQWtJbtNyGXLp3s+2XJZcey7cXSC+Y+Ysu5+4yksD8plZQ4W7qgQDmWg0ro/pCcTHxqFpYajBYHtMy3FwfV3FzdUVqpcXuLQrfJxzoW6YuRNrIAKcsYrquhIMrSWSc5hKzt8mfAmNWg3nlSLsShlOrbbZQnSjlyJKr1mL1PnEzJyRnHvfd+LniJQKv2GflixRw3xODeVp8um0AuXr7LuUkZiZxzBQKR7j5PBASIRWzNzRXk8C3Zm/NT4Y+lQSw4SogCNr/4kS6l1yQ2dyznzYyQnGZGzm6OyoRwRYcDJoaLI/B8Z+I3Wl4nq9+6y9BJReC4cOUguLCIXDGCWaTQf7P7/DabZSXAq1w6K1PplIjjw5EQgERQWq7K61c+czuqy9dr6w+MkXAoSngu00tZQoEFoel9PNr+QEVYjE4lKI1WVV9vxSbGwCfoNHOnOzBtKRWAx+g+8TqYA0CRgiB/xGZ8ldgwR0ronf8HLqKl4ZJGBzW/gNL6dNAlajVoffoHmRIrRUF/hBF+M/gap8unuigPvNs1C+ovrnAvJq3hUCjR1h42sd1MUpXZ5z0pEd1czZciY3ts+KBfgFu1LiV44+0duGgU3aJj9U4OxJgI6Z920CfC+LClvl40OMGmUxqUJiq2vL7h1EjWylWLBGuRQSX7xh2QKi2e6zWwKsBReKWRD3SgM5bDBmxxkr5v0e4cFRTFlctFaOrF9DVYIzZ0zGftwb/KMxcfP61LQxLY2JNqBZaUrLVi4ckoA38z4akzsSujiWUFYLR1YnEzOYEM/YLxEmPisVcFqSneU0t7jm/9WaPSQya0j7mZWBX2jd6J7oHyLRGAJey8aEjXr9tjGVRtWWxNsSfnPg5vQpkU57LmQ+y58+omd7ru7yhOu3Pf8L0LJz6BL91y8AAAAASUVORK5CYII=',
    'iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMcSURBVHgBxVdNTttAFH4zjhOHQGL+SQWSI7WiEovSrrqjnKCw7KrNCcoNgBvACSgnKD1B2xNAF5WgDaqlBCWEEDspDrEdezrPJG4LSUlDYj5pIsW25vvmvZk33yPQJXYURQZJWCEuLAGwRf5I4UNuvtb5UAHIAWPwGWxnL62qejfzkts+2HmsKMShbwVReBOTovLYyAgkhochLIoQEgTvm4bjQK1eB+PyEqq1GpQrFZz6HaPiZvrwUO1JAK6YiHQ9FBLWkhMTMDM+7hPeBtOy4EzXoahpYNrWFjPdzU4RIR1XzejH5PiEMjs11TVxOyHZYhHONE1lNLzcLho3BOwsPFokDee9knygJPmq+4F8qQRqPq8yUVhNf/1+0FFAa+UPZ+eUSVmGfoJHATK53I1I+AK8nEfofj9Xfh1+JCz3aWtPUF8J33CT8ujAyBG4mflQ+ELXfV78wdBLIenHQioFkXAYBgk8svtHR2AzIYWp8CJAXLo+NTo6cHIEnqgZHglwrDWP28t9mGrP5ucDEYDwovDtSLdrdopieR2LxwMjR2AUhiKSDKKwQonLluK8tAaNsUQCCIEl3AOLMUmCoBGPxQAvNRSgDN2DgAi/zJAbBci91vq7oMkpU7hnoAAdj0XQaHLqKEC1bBuCBhoY5KYM2JeKYUDQMDwB5IACpZ+uLFSwqFxcAPpHCnVnr2bWA90HJk+5Vq0CmleK97JtO7v583MICsVyGdC0IvfVMRTcrQI3C0FEAVePhhUdM/73BKQPVdVuONs5biAHjezpKdRNa7tly34XItvdyJ+XVLRNgwLOjQ4ZuVrPfAGYD0bcZbWQV9HP9xs4p+cH0ZT+0SP8VYoxFSwkrB6f5PoaCZzrGB0x2vJrvcE/GxM0qXPT062b67/RcF3IFQrAT1jHxqTtNfihpOsvRxK7hmVGtZ/V53g6pEik6w4JiU/4hs5ks7zgGNs856/SmUyh3bddNafgkg0C5DVaNzQSsWgUhvgIUeoTWrwNw+pW5gXGwMLGawsI4a2em9MbQprtObjuCy7mCXRqzylvz+vdt+e/ADEUl5UJA2BwAAAAAElFTkSuQmCC'
  ],

  formatNumber: {
    twoDigit: function(n) {
      var int = parseInt(n);

      return int < 10 ? "0" + int : int;
    }
  },

  formatDate: {
    system: function(date, localTime = false) {
      // Will return date in a format of "2018-02-28 10:30:00"
      let d = moment.utc(date);

      d = localTime ? d.local() : d.utc();
      
      return d.format("YYYY-MM-DD HH:mm:ss");
    },
    short: function(date, localTime = false) {
      // Will return date in a format of "02/28/2018"
      let d = moment.utc(date);

      d = localTime ? d.local() : d.utc();

      return d.format("MM/DD/YYYY");
    },
    long: function(date, localTime = false) {
      // Will return date in a format of "Sunday, February 14th 2019, 3:25 PM"
      let d = moment.utc(date);

      d = localTime ? d.local() : d.utc();

      return d.format("dddd, MMMM Do YYYY, hh:mm A");
    },
    time: function(date, localTime = false) {
      // Will return date in a format of "10:30 AM"
      let d = moment.utc(date);

      d = localTime ? d.local() : d.utc();

      return d.format("hh:mm A");
    },
    full: function(date, localTime = false) {
      // Will return date in a format of "02/28/2018 at 10:30 AM"
      let d = moment.utc(date);

      d = localTime ? d.local() : d.utc();

      return d.format("MM/DD/YYYY") + " at " + d.format("hh:mm A");
    },
    dayMonth: function(date, localTime = false) {
      // Will return date in a format of "11 May"
      let d = moment.utc(date);

      d = localTime ? d.local() : d.utc();

      return d.format("DD MMM");
    }
  },

  /*
    Get color darken or lighten
    colorShade(color, -10); // darken color
    colorShade(color, 10); // lighten color
  */
  colorShade: function(color, intensity) {
    color = color.replace(/^#/, '')
    
    if (color.length === 3) color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2]

    let [r, g, b] = color.match(/.{2}/g);
    ([r, g, b] = [parseInt(r, 16) + intensity, parseInt(g, 16) + intensity, parseInt(b, 16) + intensity])

    r = Math.max(Math.min(255, r), 0).toString(16)
    g = Math.max(Math.min(255, g), 0).toString(16)
    b = Math.max(Math.min(255, b), 0).toString(16)

    const rr = (r.length < 2 ? '0' : '') + r
    const gg = (g.length < 2 ? '0' : '') + g
    const bb = (b.length < 2 ? '0' : '') + b

    return `#${rr}${gg}${bb}`
  },
};
