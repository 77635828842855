<template>
  <tr>
    <td>
      <DetailsButton
        title="App Details"
        entity-type="fulcrumAppDetails"
        :entity="formId"
      >
        <a
          :href="fulcrumAppUrl"
          title="Dashboard"
        >{{ name }}</a>
      </DetailsButton>
    </td>

    <td class="text-end">
      {{ lastSync ? h$.formatDate.full(lastSync, true) : 'N/A'}}
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import DetailsButton from "../shared/grid/details_button";

import helpers from "../../helpers.js";

export default {
  extends: Row,

  components: {
    DetailsButton
  },

  props: {
    index: Number,
    id: String,
    formId: String,
    name: String,
    lastSync: String
  },

  setup () {
    return {
      h$: helpers
    }
  },

  computed: {
    fulcrumAppUrl: function () {
      return "/fulcrum_apps/" + this.id
    }
  }
}
</script>