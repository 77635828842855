<template>
  <div 
    class="table-responsive"
    v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
  >
    <table class="table">
    <thead>
      <tr>
        <SortableHeader
          class="text-start"
          :current-sort="orderBy"
          :sort-by="'NAME'"
          @sort="reorder"
        >Activity</SortableHeader>

        <SortableHeader
          style="width: 200px"
          :current-sort="orderBy"
          :sort-by="'SITE_NAME'"
          @sort="reorder"
        >Site</SortableHeader>

        <SortableHeader
          class="text-center"
          style="width: 110px"
          :current-sort="orderBy"
          :sort-by="'DRAFT_FINDINGS'"
          @sort="reorder"
        >In Draft <strong>I</strong></SortableHeader>

        <SortableHeader
          class="text-center"
          style="width: 110px"
          :current-sort="orderBy"
          :sort-by="'REVIEW_FINDINGS'"
          @sort="reorder"
        >In Review <strong>I</strong></SortableHeader>

        <SortableHeader
          class="text-center"
          style="width: 110px"
          :current-sort="orderBy"
          :sort-by="'APPROVED_FINDINGS'"
          @sort="reorder"
        >Approved <strong>I</strong></SortableHeader>

        <SortableHeader
          class="text-center"
          style="width: 110px"
          :current-sort="orderBy"
          :sort-by="'DRAFT_REMEDIATIONS'"
          @sort="reorder"
        >In Draft <strong>R</strong></SortableHeader>

        <SortableHeader
          class="text-center"
          style="width: 110px"
          :current-sort="orderBy"
          :sort-by="'REVIEW_REMEDIATIONS'"
          @sort="reorder"
        >In Review <strong>R</strong></SortableHeader>

        <SortableHeader
          class="text-center"
          style="width: 110px"
          :current-sort="orderBy"
          :sort-by="'APPROVED_REMEDIATIONS'"
          @sort="reorder"
        >Approved <strong>R</strong></SortableHeader>

        <SortableHeader
          class="text-end"
          style="width: 120px"
          :current-sort="orderBy"
          :sort-by="'DATE'"
          @sort="reorder"
        >Date</SortableHeader>

        <th data-sorter="false" class="d-none d-md-table-cell"></th>
      </tr>
    </thead>

    <tbody>
      <Inspection
        v-for="(item, index) in items"
        v-bind="item"
        :key="item.id"
        :index="index"
        :policy-update="policyUpdate"
        :project-id="projectId"
        @update-status="updateInspection"
        :ref="item.id"
        :selected="isItemMarked(item.id)"
      />
    </tbody>
  </table>

  <div class="spinner"></div>

  <NoRecords 
    label="There is no related activities"
  />

  <Pagination
    v-bind="pageInfo"
    entity-type="activities"
    @navigate-to="paginate"
  />
</div>
</template>

<script>
import Grid from "../shared/grid/grid";

import Inspection from "./inspection";

export default {
  extends: Grid,

  components: {
    Inspection
  },

  props: {
    projectId: String
  },

  data: function () {
    return {
      items: [],

      grid: "inspections",

      textSearch: '',

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },
      
      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    filterParams: function () {
      return { projectId: this.projectId }
    },
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.project.inspections",
        params,

        (data) => {
          let inspections = data.data.inspections,
              pageInfo = inspections.pageInfo;

          success(inspections, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch inspections!"
          })
        }
      )
    },

    updateInspection: function (updatedInspection) {
      let inspection = this.items.find(function (item) {
        return item.id === updatedInspection.id;
      });

      inspection.findingStatusCounts.approved = updatedInspection.findingStatusCounts.approved;
      inspection.findingStatusCounts.review = updatedInspection.findingStatusCounts.review;
      inspection.findingStatusCounts.draft = updatedInspection.findingStatusCounts.draft;

      inspection.remediationStatusCounts.approved = updatedInspection.remediationStatusCounts.approved;
      inspection.remediationStatusCounts.review = updatedInspection.remediationStatusCounts.review;
      inspection.remediationStatusCounts.draft = updatedInspection.remediationStatusCounts.draft;
    }
  }
}
</script>
