<template>
  <a 
    :title="title" 
    :class="['btn-expandable', 'btn-expandable-primary', css]" 
    @click="showModal"
  >
    <span class="icon" data-icon="repeat"></span>
    <span v-if="this.label">{{ label }}</span>
  </a>
</template>

<script>

export default {
  props: {
    label: String,
    title: String,
    bulkTemplateUrl: String,
    bulkUploadUrl: String,
    siteId: String,
    css: String
  },

  created: function () {
    
  },

  computed: {
    
  },

  methods: {
    showModal: function() {
      const vm = this;

      vmModal.$refs.modal.show({
        template: 'siteBulkUpdateModal',
        title: 'Site Bulk Update',
        args: {
          bulkTemplateUrl: vm.bulkTemplateUrl,
          bulkUploadUrl: vm.bulkUploadUrl,
          siteId: vm.siteId,
          callback: () => {
            
          }
        }
      })
    }
  }
}
</script>
