<template>
  <div class="dashboard">
    <div class="row gx-4 gy-4 mb-4">
      <div class="col-md-6"><div class="dashboard-last-sync"><strong>{{  h$.formatDate.full(appLastSync, true) }}</strong> Last Refresh</div></div>
      <div class="col-md-6">
        <v-select
          multiple
          placeholder="Regions"
          ref="regions"
          class="dashboard-regions"
          :options="regions"
          label="name"
          :loading="isSpinnerVisible"
          @option:selected="selectRegions"
          @option:deselected="deselectRegions"
        />
      </div>
    </div>
    <div class="row gx-4 gy-4 mb-4">
      <div class="col-md-3">
        <div v-bind:class="['dashboard-card', 'dashboard-card-status-overall', { 'spinner-is-visible': isSpinnerVisible }]">
          <div class="dashboard-card-header pb-0">
            <div class="dashboard-card-title">
              Overall Status
            </div>
            <div v-if="!isSpinnerVisible" class="dashboard-card-total">
              Total Assets <strong>{{ overallStatuses.totalStatuses }}</strong>
            </div>
          </div>
          <div class="dashboard-card-content">
            <BarsChart
              ref="overallStatus"
              v-if="!isSpinnerVisible" 
              title=""
              :data="overallStatuses.data"
              bars-min-width="10px"
              :relative-to-highest-value="false"
              :include-counts="true"
            />
          </div>
          <div class="spinner"></div>
        </div>
      </div>
      <div class="col-md-3">
        <div v-bind:class="['dashboard-card', 'dashboard-card-rejected-priority-poles', { 'spinner-is-visible': isSpinnerVisible }]">
          <div class="dashboard-card-header pb-0">
            <div class="dashboard-card-title">
              Rejected and Priority Poles
            </div>
          </div>
          <div class="dashboard-card-content">
            <div v-if="!isSpinnerVisible" class="rejected-priority-poles-charts">
              <div>
                <div class="chart-total">Rejected Poles <strong>1350</strong></div>
                <div class="chart-header">
                  <div><strong>23</strong> Resortable</div>
                  <div>Non-Resortable <strong>77</strong></div>
                </div>
                <RowChart
                  ref="rejectedPoles"
                  v-if="!isSpinnerVisible"
                  :data="rejectedPoles.data"
                  bars-min-width="5px"
                />
              </div>
              <div>
                <RowChart
                  ref="priorityPoles"
                  v-if="!isSpinnerVisible"
                  :data="priorityPoles.data"
                  bars-min-width="5px"
                />
                <div class="chart-header">
                  <div><strong>69</strong> Resortable</div>
                  <div>Non-Resortable <strong>31</strong></div>
                </div>
                <div class="chart-total">Priority Poles <strong>103</strong></div>
              </div>
            </div>
          </div>
          <div class="spinner"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div v-bind:class="['dashboard-card', 'dashboard-card-inspection-types', { 'spinner-is-visible': isSpinnerVisible }]">
          <div class="dashboard-card-header pb-0">
            <div class="dashboard-card-title">
              Inspection Types
            </div>
            <div v-if="!isSpinnerVisible" class="dashboard-card-total">
              Total Inspections <strong>{{ inspectionTypes.totalInspectionTypes }}</strong>
            </div>
          </div>
          <div class="dashboard-card-content">
            <BarsChart
              ref="inspectionTypes"
              v-if="!isSpinnerVisible"
              :data="inspectionTypes.data"
              bars-min-width="10px"
              :relative-to-highest-value="true"
              :include-counts="true"
            />
          </div>
          <div class="spinner"></div>
        </div>
      </div>
    </div>
    <div class="row gx-4 gy-4 mb-4">
      <div class="col-md-6">
        <div class="dashboard-card">
          <project-assets-map 
            ref="projectSitesMap"
            :assets="filteredAssets"
            :appId="appId"
            :googleMapApiKey="googleMapApiKey"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="row gy-3">
          <div class="col-sm-12">
            <div v-bind:class="['dashboard-card', 'dashboard-card-weekly-progress', {'view-grid': weeksInspections.dataViewMode == 'grid'}, {'view-graph': weeksInspections.dataViewMode == 'graph'}, { 'spinner-is-visible': isSpinnerVisible }]">
              <div class="dashboard-card-header pb-0">
                <div class="dashboard-card-title">
                  Weekly progress
                </div>
                <div v-if="!isSpinnerVisible" class="d-flex align-items-center">
                  <div class="dashboard-card-total">
                    Total Projects <strong>{{ weeksInspections.inspections }}</strong>
                  </div>
                  <div class="dashboard-card-toggle btn-group ps-4">
                    <button :class="['btn btn-sm btn-info', {'btn-inverse-info': weeksInspections.dataViewMode == 'grid'}]" @click="toggleWeeklyProgressView('graph')">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5 4L13.25 5.75L9.5 9.5L6.5 6.5L0 13L1 14L6.5 8.5L9.5 11.5L14.25 6.75L16 8.5V4H11.5Z" fill="#fff"/>
                      </svg>
                    </button>
                    <button :class="['btn btn-sm btn-info', {'btn-inverse-info': weeksInspections.dataViewMode == 'graph'}]" @click="toggleWeeklyProgressView('grid')">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 3V14H16V3H0ZM6 10V8H10V10H6ZM10 11V13H6V11H10ZM10 5V7H6V5H10ZM5 5V7H1V5H5ZM1 8H5V10H1V8ZM11 8H15V10H11V8ZM11 7V5H15V7H11ZM1 11H5V13H1V11ZM11 13V11H15V13H11Z" fill="#fff"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="dashboard-card-content pt-0">
                <div class="view-grid">
                  <div class="table-wrapper">
                    <div
                      :class="['table-responsive', 'table-scroll', { 'spinner-is-visible': isSpinnerVisible }]"
                    >
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Week</th>
                            <th
                              class="text-end"
                              style="width: 150px"
                            >Total</th>
                          </tr>
                        </thead>
                      </table>
                      
                      <div class="table-body" style="height: 95px;">
                        <table class="table">
                          <tbody>
                            <tr 
                              v-for="(week, index) in weeksInspections.data"
                              v-bind="week"
                              :key="'week-' + index"
                              :index="index"
                              :ref="'week-' + index"
                            >
                              <td>
                                {{ m$.utc(week.startDate).format("dddd") }}, <span>{{ m$.utc(week.startDate).format("DD MMM") }}</span>, {{ m$.utc(week.startDate).format("YYYY") }}
                              </td>
                              <td class="text-end">
                                <strong>{{ week.inspections }}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div class="spinner"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="view-graph">
                  <div>
                    <LineChart
                      ref="weeklyProgress"
                      :yLabels="weeksInspections.yLabels"
                      :xLabels="weeksInspections.xLabels"
                      :height="115"
                      :data="weeksInspections.chartData"
                      :showGridXLine="false"
                      :showXlabels="false"
                    />
                  </div>
                  <div class="d-flex justify-content-between">
                    <div style="padding-left: 50px;">{{ weeksInspections.xLabels.labels[0] }}</div>
                    <div>{{ weeksInspections.xLabels.labels[weeksInspections.xLabels.labels.length - 1] }}</div>
                  </div>
                </div>
              </div>
              <div class="spinner"></div>
            </div>
          </div>
          
          <div class="col-sm-12">
            <div v-bind:class="['dashboard-card', 'dashboard-card-poles-status', {'view-all': weeksStatuses.dataViewMode == 'all'}, {'view-week': weeksStatuses.dataViewMode == 'week'}, { 'spinner-is-visible': isSpinnerVisible }]">
              <div class="dashboard-card-header pb-0">
                <div class="dashboard-card-title col-5">
                  Rejected and Priority Pole
                </div>
                <div class="d-flex align-items-center justify-content-center col-2" v-if="!isSpinnerVisible && weeksStatuses.dataViewMode == 'week'">
                  <a href="#" @click.prevent="showWeeksStatusDetails(weeksStatuses.currentWeek - 1)">
                    <svg height="18" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13 1L2 12L13 22.9999" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                  </a>
                  <span class="px-2">{{ h$.formatDate.dayMonth(weeksStatuses.chartData[weeksStatuses.currentWeek].week) }}</span>
                  <a href="#" @click.prevent="showWeeksStatusDetails(weeksStatuses.currentWeek + 1)">
                    <svg height="18" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 22.9998L12 11.9998L1 0.999878" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                  </a>
                </div>
                <div class="d-flex align-items-center col-5 justify-content-end" v-if="!isSpinnerVisible">
                  <div class="dashboard-card-total">
                    Total Poles <strong>{{ weeksStatuses.chartTotal }}</strong>
                  </div>
                  <div class="dashboard-card-toggle btn-group ps-4">
                    <button :class="['btn btn-sm btn-info', {'btn-inverse-info': weeksStatuses.dataViewMode == 'all'}]" @click="showWeeksStatusDetails(0)">Week</button>
                    <button :class="['btn btn-sm btn-info', {'btn-inverse-info': weeksStatuses.dataViewMode == 'week'}]" @click="toggleWeeksStatusesView('all')">Month</button>
                  </div>
                </div>
              </div>
              <div class="dashboard-card-content">
                <div class="view-all">
                  <div class="multi-column-chart" style="height: 120px;">
                    <div
                      v-for="(weekStatus, index) in weeksStatuses.chartData"
                      v-bind="weekStatus"
                      :key="'poles-status-' + index"
                      :index="index"
                      :ref="'poles-status-' + index"
                      class="chart-column"
                      :style="{ '--column-height': weekStatus.percentage + '%' }"
                      @click="showWeeksStatusDetails(index)"
                    >
                      <ColumnChart
                        :data="weekStatus.data"
                        :bars-min-height="'5px'"
                        :show-tooltips="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="view-week">
                  <BarsChart
                    ref="weekStatusDetails"
                    :data="weeksStatuses.currentWeekData"
                    bars-min-width="10px"
                    :relative-to-highest-value="true"
                    :include-counts="true"
                  />
                </div>
              </div>
              <div class="spinner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="dashboard-card">
          <div class="dashboard-card-content">
            <div class="grid-project-regions">
              <div class="table-wrapper">
                <div
                  class="table-responsive"
                  v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
                >
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Region</th>
                        <th
                          class="text-end"
                          style="width: 150px"
                        >Total Scope</th>
                        <th
                          class="text-end"
                          style="width: 150px"
                        >Add Pole</th>
                        <th
                          class="text-end"
                          style="width: 170px"
                        >Complete</th>
                        <th
                          class="text-end"
                          style="width: 150px"
                        >Remaining</th>
                        <th
                          class="text-end"
                          style="width: 150px"
                        >QC Count</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr 
                        v-for="(region, index) in regions"
                        v-bind="region"
                        :key="'region-' + index"
                        :index="index"
                        :ref="'region-' + index"
                      >
                        <td>
                          <DetailsButton
                            title="Region Details"
                            entity-type="blankDetails"
                            :entity="region.name"
                          >
                            {{region.name}}
                          </DetailsButton>
                        </td>
                        <td class="text-end">
                          {{ region.totalScope }}
                        </td>
                        <td class="text-end">
                          {{ region.pickUpPoles }}
                        </td>
                        <td>
                          <div class="region-completeness">
                            <circle-chart
                              css="inline-circle-chart"
                              :width="30"
                              :height="30"
                              :percentage="Math.floor((region.inspections / region.totalScope) * 100)"
                              :color="regionsGridChartColor(Math.floor((region.inspections / region.totalScope) * 100))"
                              :background-color="regionsGridChartColor(Math.floor((region.inspections / region.totalScope) * 100))"
                            />
                            {{ region.inspections }}
                          </div>
                        </td>
                        <td class="text-end">
                          {{ region.remaining }}
                        </td>
                        <td class="text-end">
                          {{ region.inspections > 0 ? ((region.statusesQC / region.inspections) * 100).toFixed(2) + ' %' : '' }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="spinner"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from "moment";

import vSelect from "vue-select";

import DetailsButton from "../shared/grid/details_button";

import RowChart from "../shared/stats/row_chart";
import ColumnChart from "../shared/stats/column_chart";
import ColumnsChart from "../shared/stats/columns_chart";
import BarsChart from "../shared/stats/bars_chart";
import LineChart from "../shared/stats/line_chart";
import CircleChart from "../shared/stats/circle_chart";

import ProjectAssetsMap from "./dashboard/map";

import Helpers from "../../helpers.js";

export default {
  components: {
    vSelect,
    DetailsButton,
    RowChart,
    ColumnChart,
    ColumnsChart,
    BarsChart,
    LineChart,
    CircleChart,
    ProjectAssetsMap
  },

  props: {
    appId: String,
    appName: String,
    appLastSync: String,
    googleMapApiKey: String
  },

  data: function () {
    return {
      ratingStatusMap: {
        0: ['NI'],
        1: ['Y', 'Y-QC'],
        2: ['Y-H20', 'Y-H20-QC'],
        3: ['Y-NRR', 'Y-NRR-QC'],
        4: ['Y-R', 'Y-R-QC', 'Y-UGR', 'Y-UGR-QC'],
        5: ['Y-PRI', 'Y-PRI-QC', 'Y-RR', 'Y-RR-QC']
      },

      inspectionTypes: {
        chartColors: [1, 6, 13, 5, 11, 9, 2, 12, 15, 7, 8, 10],
        totalInspectionTypes: 0,
        data: []
      },

      overallStatuses: {
        statusesMap: {
          'Visited':      ['Y', 'Y-QC', 'Y-H20', 'Y-H20-QC'],
          'Rejected':     ['Y-R', 'Y-R-QC', 'Y-NRR', 'Y-NRR-QC', 'Y-RR', 'Y-RR-QC', 'Y-UGR', 'Y-UGR-QC'],
          'Priority':     ['Y-PRI', 'Y-PRI-QC'],
          'Not Visited':  ['N', 'N-Pad', 'NI'],
        },
        chartColors: [2, 6, 13, 9],
        totalStatuses: 0,
        data: []
      },

      statusChoices: [],

      regions: [],
      assets: [],

      selectedRegions: [],
      
      filteredAssets: [],

      rejectedPoles: {
        data: [
          [5, 23, "Restorable <strong>23</strong>", '#F4BA1C'],
          [52, 77, "Non-Restorable <strong>77</strong>", '#C0C0C0']
        ]
      },

      priorityPoles: {
        data: [
          [70, 69, "Restorable <strong>69</strong>", '#5BC0DE'],
          [35, 31, "Non-Restorable <strong>31</strong>", '#C0C0C0']
        ]
      },

      weeksInspections: {
        xLabels: {
          title: '',
          labels: [],
          labelHeight: 15,
          offsetBottom: 0,
          offsetTop: 10
        },
        yLabels: {
          title: '',
          labels: [],
          labelWidth: 40,
          offsetLeft: 0,
          offsetRight: 10
        },
        chartData: [],
        data: [],
        dataViewMode: 'graph',
      },
      
      weeksStatuses: {
        data: [],
        chartTotal: 0,
        chartData: [],
        chartOrder: ['Y-UGR', 'Y-R', 'Y-RR', 'Y-NRR', 'Y-PRI'],
        chartColors: {
          2:  ['Y-UGR'],
          6:  ['Y-R'],
          9:  ['Y-RR'],
          1:  ['Y-NRR'],
          13: ['Y-PRI']
        },
        currentWeek: 0,
        currentWeekData: [],
        dataViewMode: 'all'
      },

      isSpinnerVisible: true
    }
  },

  setup () {
    return {
      h$: Helpers,
      m$: Moment
    }
  },

  created: function() {
    
  },

  mounted: function() {
    this.fetchDashboardData();
  },

  watch: {
    selectedRegions: function() {
      this.filterAssets()
    },
  },

  computed: {
    
  },

  methods: {
    selectRegions: function(opt) {
      this.selectedRegions = opt ? opt : [];
    },

    deselectRegions: function(opt) {
      this.selectedRegions = this.selectedRegions.filter(region => region !== opt);
    },

    setRegions(data) {
      this.regions = data.map((region) => {
        region.inspectionTypes = JSON.parse(region.inspectionTypes)
        
        region.statuses = JSON.parse(region.statuses)
        region.totalScope = Object.values(region.statuses).reduce((acc, val) => acc + val, 0)
        region.remaining = region.totalScope - region.inspections

        region.statusesQC = Object.entries(region.statuses)
          .filter(([key]) => key.endsWith("QC"))
          .reduce((acc, [, value]) => acc + value, 0)

        return region;
      });

      this.selectedRegions = this.regions;

      // console.log(this.selectedRegions)

      if (this.selectedRegions && this.selectedRegions.length > 0) {
        this.$refs.regions.updateValue(this.selectedRegions);
      }
    },
    
    regionsGridChartColor(n) {
      let color = this.h$.damageColors[5]

      if (n > 10) color = this.h$.damageColors[4]
      if (n > 50) color = this.h$.damageColors[3]
      if (n > 90) color = this.h$.damageColors[1]

      return color
    },

    setAssets(records) {
      // Create a mapping of statuses to ratings
      const statusRatingMap = Object.entries(this.ratingStatusMap).reduce((acc, [rating, statuses]) => {
        statuses.forEach(status => acc[status] = Number(rating));
        return acc;
      }, {});

      const statusColorMap = Object.fromEntries(
        this.statusChoices.map(choice => [choice.value, choice.color])
      );

      this.assets = records.map(record => ({
        ...record,
        rating: statusRatingMap[record.status] || 0,
        color: statusColorMap[record.status] || "#000000",
      }));
    },

    filterAssets() {
      this.filteredAssets = this.assets.filter(asset => new Set(this.selectedRegions.map(r => r.name)).has(asset.region));
    },

    setInspectionTypes(data) {
      let inspectionTypes = Object.entries(JSON.parse(data))
      let totalInspectionTypes = 0

      inspectionTypes.sort((a, b) => b[1] - a[1])

      inspectionTypes.forEach((r, index) => {
        totalInspectionTypes += r[1]

        r.push(this.h$.chartColors[this.inspectionTypes.chartColors[index]])
      });

      this.inspectionTypes.totalInspectionTypes = totalInspectionTypes
      this.inspectionTypes.data = inspectionTypes
    },

    setOverallStatuses(data) {
      let statuses = Object.entries(JSON.parse(data))

      let total = 0

      // Step 1: Convert data array to a lookup map
      const dataMap = new Map(statuses.map(([label, count]) => [label, count]));

      // Step 2: Aggregate by inspectionTypesMap
      let overallStatuses = Object.entries(this.overallStatuses.statusesMap).map(([group, labels]) => {
        let total = labels.reduce((sum, label) => sum + (dataMap.get(label) || 0), 0);
        return [group, total];
      });

      // Step 3: Map chart colors
      overallStatuses.forEach((r, index) => {
        total += r[1]

        r.push(this.h$.chartColors[this.overallStatuses.chartColors[index]])
      });

      this.overallStatuses.totalStatuses = total;
      this.overallStatuses.data = overallStatuses;
    },

    setOverallRestirableStatuses(data) {
      let statuses = Object.entries(JSON.parse(data))

      let total = 0

      // Step 1: Convert data array to a lookup map
      const dataMap = new Map(statuses.map(([label, count]) => [label, count]));

      // Step 2: Aggregate by inspectionTypesMap
      let overallStatuses = Object.entries(this.overallStatuses.statusesMap).map(([group, labels]) => {
        let total = labels.reduce((sum, label) => sum + (dataMap.get(label) || 0), 0);
        return [group, total];
      });

      // Step 3: Map chart colors
      overallStatuses.forEach((r, index) => {
        total += r[1]

        r.push(this.h$.chartColors[this.overallStatuses.chartColors[index]])
      });

      this.overallStatuses.totalStatuses = total;
      this.overallStatuses.data = overallStatuses;
    },

    setWeeksInspectionsAndStatuses(weeks) {
      let weeksInspections = []
      let weeksStatuses = []
      let totalInspections = 0

      weeks.forEach((week, index) => {
        totalInspections += week.inspections

        weeksInspections.push({
          startDate: week.startDate,
          inspections: week.inspections,
          inspectionTypes: JSON.parse(week.inspectionTypes)
        })

        weeksStatuses.push({
          startDate: week.startDate,
          statuses: JSON.parse(week.statuses)
        })
      })

      this.weeksInspections.inspections = totalInspections
      this.weeksInspections.data = weeksInspections.slice().sort((a, b) => a.startDate.localeCompare(b.startDate))

      this.setWeekInspectionsData()

      this.weeksStatuses.inspections = totalInspections
      this.weeksStatuses.data = weeksStatuses.slice().sort((a, b) => a.startDate.localeCompare(b.startDate));

      this.setWeekStatusData()
    },

    toggleWeeklyProgressView(mode) {
      this.weeksInspections.dataViewMode = mode
    },
    
    setWeekInspectionsData() {
      function generateFiveStepRange(lowest, highest) {
        let roundedLowest = Math.floor(lowest / 100) * 100;  // Round down
        let roundedHighest = Math.ceil(highest / 100) * 100; // Round up

        let step = Math.ceil((roundedHighest - roundedLowest) / 4 / 100) * 100; // Determine step, rounded to nearest 100
        let range = Array.from({ length: 5 }, (_, i) => roundedLowest + i * step);

        return range;
      }

      let vm = this;

      function generateTooltip(inspectionsData) {
        if (!inspectionsData || !inspectionsData.startDate || !inspectionsData.inspectionTypes) return '';

        let html = `<div class="tooltip-weekly-status"><div class="tooltip-header"><span>${vm.h$.formatDate.dayMonth(inspectionsData.startDate)}</span> <strong>${inspectionsData.inspections}</strong></div>\n<ul>\n`;

        for (let [type, count] of Object.entries(inspectionsData.inspectionTypes)) {
            html += `<li>${type} <span>${count}</span></li>\n`;
        }

        html += `</ul></div>`;

        return html; 
      } 

      let inspections = this.weeksInspections.data.map(item => item.inspections);

      this.weeksInspections.yLabels.labels = generateFiveStepRange(Math.min(...inspections), Math.max(...inspections)).reverse()

      this.weeksInspections.xLabels.labels = this.weeksInspections.data.map((item, index) => { 
        return (index == 0 || index == this.weeksInspections.data.length - 1) ? this.h$.formatDate.dayMonth(item.startDate) : ''
      })

      this.weeksInspections.chartData = this.weeksInspections.data.map((item, index) => { 
        return {
          value: item.inspections,
          title: item.startDate,
          tooltip: generateTooltip(item)
        }
      })

      // console.log('weeksInspections.data', this.weeksInspections.data)
      // console.log('weeksInspections.chartData', this.weeksInspections.chartData)
    },

    setWeekStatusData() {
      let vm = this;
      
      function generateTooltip(inspectionDate, inspectionsData) {
        let html = `<div class="tooltip-weekly-status"><div class="tooltip-header"><span>${vm.h$.formatDate.dayMonth(inspectionDate)}</span></div>\n<ul>\n`;

        inspectionsData.forEach((item) => {
          html += `<li>${item[0]} <span>${item[1]}</span></li>\n`;
        })

        html += `</ul></div>`;

        return html; 
      } 

      this.weeksStatuses.chartData = this.weeksStatuses.data.map((item, index) => {
        let weekChartData = this.getWeekStatusData(item.statuses)

        return {
          total: weekChartData.reduce((sum, item) => sum + item[1], 0),
          week: item.startDate,
          data: weekChartData,
          tooltip: generateTooltip(item.startDate, weekChartData)
        }
      })

      let highestTotal = Math.max(...this.weeksStatuses.chartData.map(item => item.total));

      this.weeksStatuses.chartData.forEach((item) => {
        item.percentage = item.total / highestTotal * 100
      })

      // console.log('weeksStatuses', this.weeksStatuses)

      this.$nextTick(() => {
        this.weeksStatuses.chartData.forEach((week, index) => {
          const p = this.$refs['poles-status-' + index];

          const t = new bootstrap.Tooltip(p[0], {
            title: week.tooltip,
            html: true,
            placement: 'bottom',
            trigger : 'hover'
          })
        })
      });
    },

    getWeekStatusData(weekStatuses) {
      // console.log('weekStatuses',weekStatuses)

      let weekStatusData = []

      // Step 1: Exclude both 'Visited' and 'Not Visited'. Filter entries that start with "Y-" and contain "R" after that
      Object.entries(weekStatuses).forEach((s) => {
        if (s[0].startsWith('Y-') && /R/.test(s[0].substring(2))) {
          weekStatusData.push(s)
        }
      })

      // Step 2: Merge "-QC" variants into their base variants
      let mergedWeekStatusData = new Map();

      weekStatusData.forEach(([key, value]) => {
        let baseKey = key.endsWith("-QC") ? key.slice(0, -3) : key; // Remove "-QC" suffix

        if (mergedWeekStatusData.has(baseKey)) {
          mergedWeekStatusData.set(baseKey, mergedWeekStatusData.get(baseKey) + value);
        } else {
          mergedWeekStatusData.set(baseKey, value);
        }
      });

      // Step 3: Convert back to array
      weekStatusData = Array.from(mergedWeekStatusData);

      // Step 4: Sort arr based on weekStatusOrder array
      weekStatusData.sort((a, b) => this.weeksStatuses.chartOrder.indexOf(a[0]) - this.weeksStatuses.chartOrder.indexOf(b[0]));

      // Step 5: Map chart colors and labels
      const statusColorsMap = Object.fromEntries(
        Object.entries(this.weeksStatuses.chartColors).flatMap(([key, values]) => values.map(v => [v, Number(key)]))
      );

      const statusLabelsMap = Object.fromEntries(
        this.statusChoices.map(choice => [choice.value, choice.label])
      );

      let total = 0

      weekStatusData.forEach((r) => {
        total += r[1]
      })

      this.weeksStatuses.chartTotal += total

      weekStatusData.forEach((r) => {
        let percentage = r[1] / total * 100

        r.push(r[0])
        r.push(percentage)
        r.push(this.h$.chartColors[statusColorsMap[r[0]] || 0])

        r[0] = statusLabelsMap[r[0]] || r[0]
      });
      
      return weekStatusData
    },

    showWeeksStatusDetails(i) {      
      if (i > this.weeksStatuses.chartData.length - 1) {
        i = 0
      }

      if (i < 0) {
        i = this.weeksStatuses.chartData.length - 1
      }

      this.weeksStatuses.currentWeekData = [];
      this.weeksStatuses.currentWeek = i;
      this.weeksStatuses.dataViewMode = 'week';

      this.$nextTick(() => {
        this.weeksStatuses.currentWeekData = this.weeksStatuses.chartData[i].data.map((x) => {
          return [
            x[0],
            x[1],
            x[4]
          ]
        })
      });
    },

    toggleWeeksStatusesView(mode) {
      this.weeksStatuses.dataViewMode = mode
    },

    setRatingFrequencyBars() {
      let ratingFrequency = Object.entries(JSON.parse(this.site.statistics.ratingFrequency))

      let total = 0

      ratingFrequency.forEach((r) => {
        total += r[1]
      })

      ratingFrequency.forEach((r) => {
        let percentage = r[1] / total * 100

        r.push(r[0])
        r.push(percentage)
      });

      this.ratingFrequencyStats = ratingFrequency;
    },

    getDashboardData: function(dashboardData) {
      this.statusChoices = dashboardData.statusChoices

      // Set rating frequency bars props
      this.setInspectionTypes(dashboardData.inspectionTypes)
      
      // Handle Regions data
      this.setRegions(dashboardData.regions)

      // Handle Overall Statuses
      this.setOverallStatuses(dashboardData.statuses)

      // Handle map pins for assets map
      this.setAssets(dashboardData.records)

      // Handle Weeks Inspections and Statuses
      this.setWeeksInspectionsAndStatuses(dashboardData.weeks)

      this.isSpinnerVisible = false;
    },

    fetchDashboardData: function() {
      app.graphql.get("dashboard.project",
        {
          project: this.appName
        },
        
        (data) => {
          this.getDashboardData(data.data.fulcrumDashboard)
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch project details!"
          });
        }
      )
    }
  }
}
</script>
