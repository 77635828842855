<template>
  <div class="table-wrapper">
    <div
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              style="width: 300px"
              :current-sort="orderBy"
              :sort-by="'NAME'"
              @sort="reorder"
            >Name</SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'TITLE'"
              @sort="reorder"
            >Title</SortableHeader>

            <SortableHeader
              style="width: 100px; text-align: right"
              :current-sort="orderBy"
              :sort-by="'VERSION'"
              @sort="reorder"
            >Version</SortableHeader>

            <th data-sorter="false" class="d-none d-md-table-cell"></th>
          </tr>
        </thead>

        <tbody>
          <UserAgreement
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :policy-update="policyUpdate"
            :ref="item.id"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords 
        label="According to the search criteria, there are no user agreements found."
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="agreements"
        @navigate-to="paginate"
      />
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import UserAgreement from "./user_agreement";

export default {
  extends: Grid,

  components: {
    UserAgreement
  },

  data: function () {
    return {
      items: [],

      grid: "user-agreements",

      textSearch: '',

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },
      
      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.userAgreements",
        params,

        (data) => {
          let user_agreements = data.data.userAgreements,
              pageInfo = user_agreements.pageInfo;

          success(user_agreements, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch user agreements!"
          })
        }
      )
    }
  }
}
</script>
