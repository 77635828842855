<template>
  <div 
    :class="['stats-chart', css]"
  >
    <template v-if="title">
      <div class="chart-header">
        <h3 class="chart-title">{{ title }}: <strong>{{ total }}</strong></h3>
        <div class="chart-legend"></div>
      </div>
      <div class="chart-content">
        <div class="circle-chart">
          <svg viewBox="0 0 33.83098862 33.83098862" :width="width" :height="height" xmlns="http://www.w3.org/2000/svg">
            <circle class="chart-background" :stroke="backgroundColor" stroke-width="3" fill="none" cx="16.91549431" cy="16.91549431" r="14.91549431"></circle>
            <circle class="chart-circle" :stroke="color" stroke-width="3" :stroke-dasharray="[percentage, 100]" stroke-linecap="round" fill="none" cx="16.91549431" cy="16.91549431" r="14.91549431"></circle>
          </svg>
        </div>
      </div>
    </template>
    <template v-else>
      <svg viewBox="0 0 33.83098862 33.83098862" :width="width" :height="height" xmlns="http://www.w3.org/2000/svg">
        <circle class="chart-background" :stroke="backgroundColor" stroke-width="3" fill="none" cx="16.91549431" cy="16.91549431" r="14.91549431"></circle>
        <circle class="chart-circle" :stroke="color" stroke-width="3" :stroke-dasharray="[percentage, 100]" stroke-linecap="round" fill="none" cx="16.91549431" cy="16.91549431" r="14.91549431"></circle>
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    css: {
      type: String,
      default: ''
    },
    percentage: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    },
    color: {
      type: String,
      default: '#999'
    },
    backgroundColor: {
      type: String,
      default: '#efefef'
    },
  },

  data() {
    return {
      
    }
  },

  watch: {
    percentage: {
      handler(newValue, oldValue) {        

      }, 
      immediate: true
    }
  },

  mounted: function() {
    
  },

  methods: {
    
  }
}

</script>